import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axiosAuth from "../axios/Interceptors";
import AppContext from "../Context/Context";
import ElapsedTime from "./ElapsedTime";
import { useGlobalState } from "../Context/GlobalStateContext";
import moment from "moment";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { Modal } from "react-bootstrap";

type Props = {
  EventDetails: any;
  ShowModal: boolean;
};
export default function ManualEntriesModal(props: Props) {
  const globalState = useGlobalState();
  const [manualEntryObject, setManualEntryObject] = useState({
    mgeventid: 0,
    siteuserid: 0,
    name: 0,
    startdatetime: "",
    starttime: "",
    endtime: "",
    enddatetime: "",
    durationinminutes: 0,
    hasWastage: false,
    wastageminutes: 0,
    wastagecomments: "",
    staffcomments: "",
    managementcomments: "",
    ismanualentry: true,
    manualentrytime: new Date(),
    manuallyenteredbyid: 0,
    ptorderby: 0,
    objectstatus: 0,
  });

  const [firstTime, setFirstTime] = useState(true);
  const [loadingState, setLoadingState] = useState(false);
  const [showModal, setShowModal] = useState(props.ShowModal);
  const [logsDetailData, setLogsDetailData] = useState<any>([]);

  const [deletePopupObject, setDeletePopupObject] = useState({
    showDeletePopUp: false,
    seletedID: 0,
    seletedObject: {},
  });

  useEffect(() => {
    if (firstTime) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }

    setFirstTime(false);

    setManualEntryObject({
      mgeventid: 0,
      siteuserid: 0,
      name: 0,
      startdatetime: "",
      starttime: "",
      endtime: "",
      enddatetime: "",
      durationinminutes: 0,
      hasWastage: false,
      wastageminutes: 0,
      wastagecomments: "",
      staffcomments: "",
      managementcomments: "",
      ismanualentry: true,
      manualentrytime: new Date(),
      manuallyenteredbyid: 0,
      ptorderby: 0,
      objectstatus: 0,
    });
  }, [props.ShowModal]);

  function checkValidation() {
    if (
      !manualEntryObject.startdatetime ||
      manualEntryObject.startdatetime == null ||
      manualEntryObject.startdatetime == ""
    ) {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Please enter Date",
      });
    } else if (manualEntryObject.starttime == "") {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Please enter start time",
      });
    } else if (manualEntryObject.endtime == "") {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Please enter end time",
      });
    } else if (
      manualEntryObject.starttime != "" &&
      manualEntryObject.endtime != ""
    ) {
      timeLogSaveCustom();
      // alert("success");
    }
  }

  function timeLogSaveCustom() {
    let object: any = {};
    object.typeid = 1;
    object.mgeventid = props.EventDetails.MGEventID;
    object.siteuserid = props.EventDetails.SiteUserID;
    object.name = props.EventDetails.Name;
    object.startdatetime = addTimeToDate(
      manualEntryObject.startdatetime,
      manualEntryObject.starttime
    );
    object.enddatetime = addTimeToDate(
      manualEntryObject.startdatetime,
      manualEntryObject.endtime
    );
    object.durationinminutes = manualEntryObject.durationinminutes;
    object.wastageminutes =
      manualEntryObject.hasWastage == true
        ? manualEntryObject.wastageminutes
        : 0;
    object.wastagecomments =
      manualEntryObject.hasWastage == true
        ? manualEntryObject.wastagecomments
        : 0;
    object.staffcomments = manualEntryObject.staffcomments;
    object.managementcomments = manualEntryObject.managementcomments;
    object.ismanualentry = manualEntryObject.ismanualentry;
    object.manualentrytime = manualEntryObject.manualentrytime;
    // object.manuallyenteredbyid = manualEntryObject.manuallyenteredbyid;
    // object.ptorderby = manualEntryObject.ptorderby;
    // object.objectstatus = manualEntryObject.objectstatus;
    setLoadingState(true);
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGTimeLog/MGTimeLogSaveCustom", object)
      .then((response) => {
        if (response.data.Status === 1) {
          setShowModal(false);
        }
        setLoadingState(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function addTimeToDate(date: any, timeToAdd: string): Date {
    const timeRegex = /^([0-9]+):([0-5][0-9])$/; // Regex to validate "HH:mm" format

    if (!timeRegex.test(timeToAdd)) {
      throw new Error("Invalid time format. Use 'HH:mm'.");
    }

    // Extract hours and minutes from the time string
    const [hours, minutes] = timeToAdd.split(":").map(Number);

    // Clone the original Date object to avoid modifying it
    const updatedDate = new Date(date);

    // Add the hours and minutes
    updatedDate.setHours(updatedDate.getHours() + hours);
    updatedDate.setMinutes(updatedDate.getMinutes() + minutes);

    return updatedDate;
  }

  return (
    <>
      {loadingState && <LoadingAnimation />}
      {showModal && (
        <>
          <div className="task-detail-fix custom--scroll ">
            <div className="flex-d flex-ac flex-jsb p-all10">
              <h3 className="event-title">
                Log Time For : {props.EventDetails.Name}
              </h3>
              <a
                title="Close"
                onClick={() => setShowModal(false)}
                className="close-help"
              >
                <img
                  src="/images/icon-cross.png"
                  width="20px"
                  height="20px"
                  alt="cross"
                />
              </a>
            </div>
            {/* className="input--type__text input--type__textsm" */}
            <div className="grid-d p-lr10 font-14">
              <div className="modal--create__inputs mt--10">
                <span className="input--text">Date:</span>
                <input
                  className="input--type__text input--type__textsm"
                  type="date"
                  value={manualEntryObject.startdatetime}
                  onChange={(e: any) =>
                    setManualEntryObject((prevState) => ({
                      ...prevState,
                      startdatetime: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="modal--create__inputs mt--10">
                <span className="input--text">Start Time (e.g 12:00 PM)</span>
                <input
                  className="input--type__text input--type__textsm"
                  type="time"
                  value={manualEntryObject.starttime}
                  onChange={(e: any) =>
                    setManualEntryObject((prevState) => ({
                      ...prevState,
                      starttime: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="modal--create__inputs mt--10">
                <span className="input--text">End Time (e.g 12:00 PM) </span>
                <input
                  className="input--type__text input--type__textsm"
                  type="time"
                  value={manualEntryObject.endtime}
                  onChange={(e: any) =>
                    setManualEntryObject((prevState) => ({
                      ...prevState,
                      endtime: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="modal--create__inputs mt--10">
                <span className="input--text">Comments:</span>
                <textarea
                  className="input--type__text input--type__textarea"
                  placeholder="Please enter comments..."
                  value={manualEntryObject.staffcomments}
                  onChange={(e: any) =>
                    setManualEntryObject((prevState) => ({
                      ...prevState,
                      staffcomments: e.target.value,
                    }))
                  }
                ></textarea>
              </div>
              <div className="modal--create__inputs mt--10">
                <span className="input--text">Has Wastage?</span>
                <input
                  className="input-check"
                  type="checkbox"
                  checked={manualEntryObject.hasWastage ? true : false}
                  onClick={(e: any) =>
                    setManualEntryObject((prevState) => ({
                      ...prevState,
                      hasWastage: e.target.checked,
                    }))
                  }
                />
              </div>
              {manualEntryObject.hasWastage && (
                <>
                  <div className="modal--create__inputs mt--10">
                    <span className="input--text">Time (in Minutes):</span>
                    <input
                      className="input--type__text input--type__textsm"
                      placeholder="Please enter Time Estimated(in Minutes)..."
                      value={manualEntryObject.wastageminutes}
                      onChange={(e: any) =>
                        setManualEntryObject((prevState) => ({
                          ...prevState,
                          wastageminutes: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="modal--create__inputs mt--10">
                    <span className="input--text">Wastage Comments:</span>
                    <textarea
                      className="input--type__text input--type__textarea"
                      placeholder="Please enter wastage comments..."
                      value={manualEntryObject.wastagecomments}
                      onChange={(e: any) =>
                        setManualEntryObject((prevState) => ({
                          ...prevState,
                          wastagecomments: e.target.value,
                        }))
                      }
                    ></textarea>
                  </div>
                </>
              )}
              <div className="modal--button mt--20 flex-gap10">
                <a onClick={() => checkValidation()}>Save</a>
                {/* <a onClick={() => setShowModal(false)}>Cancel</a> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
