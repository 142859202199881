import React from "react";
import AppContext from "../Context/Context";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";

import { useGlobalState } from "../Context/GlobalStateContext";
import axiosAuth from "../axios/Interceptors";
import TableGridView from "../App/DynamicGridView";
import { getEndOfMonth, getStartOfMonth } from "../Shared/Utility";
function Reporting() {
  const globalState = useGlobalState();
  const [screenID, setScreenID] = useState(0);

  const [showAnimation, setShowAnimation] = useState(false);
  const [showReportGrid, setShowReportGrid] = useState(false);
  const [reportGridData, setReportGridData] = useState<any>([]);

  const [filtersDropdownData, setFiltersDropdownData] = useState({
    ReportSectionData: [],
    ReportSectionButtonData: [],
    ProjectData: [],
    UserData: [],
  });

  const [selectedFiltersDropdownObject, setSelectedFiltersDropdownObject] =
    useState({
      projectid: 0,
      listid: 0,
      from: getStartOfMonth(),
      to: getEndOfMonth(),
      userid: 0,
      dateformate: 1,
    });

  useEffect(() => {
    getReportingListsData();
  }, []);

  function getReportingListsData() {
    let object = {} as any;
    object.masterbpid = 0;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/MGReporting/GetReportingListsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setFiltersDropdownData((prevState: any) => ({
            ...prevState,
            ReportSectionData: l_response[0],
            ReportSectionButtonData: l_response[1],
            ProjectData: l_response[2],
            UserData: l_response[3],
          }));
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function downloadJLRReport(reportNumber: any) {
    setShowAnimation(true);
    let object = {} as any;
    object.reportnumber = reportNumber;
    object.reporttype = 1;
    object.projectid = selectedFiltersDropdownObject.projectid;
    object.listid = selectedFiltersDropdownObject.listid;
    object.from = selectedFiltersDropdownObject.from;
    object.to = selectedFiltersDropdownObject.to;
    object.userid = selectedFiltersDropdownObject.userid;
    setReportGridData([]);
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGReporting/DownloadReport", object)
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          if (l_response && l_response[0] && l_response[0].Table) {
            setReportGridData(l_response[0].Table);
          }
          setShowReportGrid(true);

          // console.log(l_response);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <section className="msg--body">
        <div className="msg--body__lt ">
          <div className="msg--body__lt__head flex-d flex-ac">
            <div className="flex-d flex-ac">
              {showReportGrid == true && (
                <>
                  <a
                    title="Go Back"
                    className="wh-24"
                    onClick={() => setShowReportGrid(false)}
                  >
                    <img
                      src="images/rightg.png"
                      alt="img"
                      width={8}
                      height={16}
                      className="rotate-180d"
                    />
                  </a>
                </>
              )}
              Reporting
            </div>
          </div>
          <div className="msg--body__messages p-all10 p-unset">
            {showReportGrid == false && (
              <>
                <div className="flex-d flex-ac flex-gap10">
                  {filtersDropdownData.ProjectData &&
                    filtersDropdownData.ProjectData.length > 1 && (
                      <>
                        <select
                          className="input--type__text"
                          value={selectedFiltersDropdownObject.projectid}
                          onChange={(e) =>
                            setSelectedFiltersDropdownObject(
                              (prevState: any) => ({
                                ...prevState,
                                projectid: e.target.value,
                              })
                            )
                          }
                        >
                          <option value={0}>All Projects</option>
                          {filtersDropdownData.ProjectData &&
                            filtersDropdownData.ProjectData.map(
                              (Item: any, index: any) => (
                                <option value={Item.PID} key={Item.PID}>
                                  {Item.Name}
                                </option>
                              )
                            )}
                        </select>

                        <select
                          className="input--type__text"
                          value={selectedFiltersDropdownObject.userid}
                          onChange={(e) =>
                            setSelectedFiltersDropdownObject(
                              (prevState: any) => ({
                                ...prevState,
                                userid: e.target.value,
                              })
                            )
                          }
                        >
                          <option value={0}>All Users</option>
                          {filtersDropdownData.UserData &&
                            filtersDropdownData.UserData.map(
                              (Item: any, index: any) => (
                                <option value={Item.PID} key={Item.PID}>
                                  {Item.Name}
                                </option>
                              )
                            )}
                        </select>
                      </>
                    )}

                  <input
                    className="input--type__text"
                    placeholder="Start Date"
                    type="date"
                    value={selectedFiltersDropdownObject.from}
                    onChange={(e) =>
                      setSelectedFiltersDropdownObject((prevState: any) => ({
                        ...prevState,
                        from: e.target.value,
                      }))
                    }
                  />

                  <input
                    className="input--type__text"
                    placeholder="End Date"
                    type="date"
                    value={selectedFiltersDropdownObject.to}
                    onChange={(e) =>
                      setSelectedFiltersDropdownObject((prevState: any) => ({
                        ...prevState,
                        to: e.target.value,
                      }))
                    }
                  />

                  <select
                    className="input--type__text"
                    value={selectedFiltersDropdownObject.dateformate}
                    onChange={(e) =>
                      setSelectedFiltersDropdownObject((prevState: any) => ({
                        ...prevState,
                        dateformate: e.target.value,
                      }))
                    }
                  >
                    <option value={1}>UK</option>
                    <option value={2}>US</option>
                  </select>
                </div>

                {filtersDropdownData.ReportSectionData &&
                  filtersDropdownData.ReportSectionData.map(
                    (SectionItem: any, SectionIndex: any) => (
                      <React.Fragment key={SectionIndex}>
                        <div className="report-accord">
                          <div className="acco-head">{SectionItem.Name}</div>
                          <div className="acco-btns flex-d flex-ac flex-gap10">
                            {filtersDropdownData.ReportSectionButtonData &&
                              filtersDropdownData.ReportSectionButtonData.map(
                                (Item: any, index: any) => (
                                  <React.Fragment key={index}>
                                    {SectionItem.MGReportingSectionID ==
                                      Item.MGReportingSectionID && (
                                      <div className="btn-info-rep flex-d">
                                        <a
                                          className="flex-d flex-ac flex-gap10"
                                          onClick={() =>
                                            downloadJLRReport(Item.ReportNumber)
                                          }
                                        >
                                          <span
                                            className="btn-icon flex-d"
                                            title={Item.Info}
                                          >
                                            <img
                                              src="images/r-info.svg"
                                              width={7}
                                              height={15}
                                              alt="icon"
                                            />
                                          </span>
                                          <span className="c-line"></span>
                                          <span className="btn-txt">
                                            {Item.Name}
                                          </span>
                                        </a>
                                      </div>
                                    )}
                                  </React.Fragment>
                                )
                              )}
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  )}
              </>
            )}

            {showReportGrid == true && reportGridData.length > 0 && (
              <>
                <div className="flex-d flex-ac flex-gap10">
                  {filtersDropdownData.ProjectData &&
                    filtersDropdownData.ProjectData.length > 1 && (
                      <>
                        <div className="grid-d grid-w">
                          <span className="txt-label-sm">Project</span>
                          <select
                            className="input--type__text_no_border p-all0"
                            disabled
                            value={selectedFiltersDropdownObject.projectid}
                            onChange={(e) =>
                              setSelectedFiltersDropdownObject(
                                (prevState: any) => ({
                                  ...prevState,
                                  projectid: e.target.value,
                                })
                              )
                            }
                          >
                            <option value={0}>All Projects</option>
                            {filtersDropdownData.ProjectData &&
                              filtersDropdownData.ProjectData.map(
                                (Item: any, index: any) => (
                                  <option value={Item.PID} key={Item.PID}>
                                    {Item.Name}
                                  </option>
                                )
                              )}
                          </select>
                        </div>
                        <div className="grid-d grid-w">
                          <span className="txt-label-sm">User:</span>
                          <select
                            className="input--type__text_no_border p-all0"
                            disabled
                            value={selectedFiltersDropdownObject.userid}
                            onChange={(e) =>
                              setSelectedFiltersDropdownObject(
                                (prevState: any) => ({
                                  ...prevState,
                                  userid: e.target.value,
                                })
                              )
                            }
                          >
                            <option value={0}>All Users</option>
                            {filtersDropdownData.UserData &&
                              filtersDropdownData.UserData.map(
                                (Item: any, index: any) => (
                                  <option value={Item.PID} key={Item.PID}>
                                    {Item.Name}
                                  </option>
                                )
                              )}
                          </select>
                        </div>
                      </>
                    )}
                  <div className="grid-d grid-w">
                    <span className="txt-label-sm">Start Date:</span>
                    <input
                      className="input--type__text_no_border p-all0"
                      placeholder="Start Date"
                      type="date"
                      disabled
                      value={selectedFiltersDropdownObject.from}
                      onChange={(e) =>
                        setSelectedFiltersDropdownObject((prevState: any) => ({
                          ...prevState,
                          from: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="grid-d grid-w">
                    <span className="txt-label-sm">End Date:</span>
                    <input
                      className="input--type__text_no_border p-all0"
                      placeholder="End Date"
                      type="date"
                      disabled
                      value={selectedFiltersDropdownObject.to}
                      onChange={(e) =>
                        setSelectedFiltersDropdownObject((prevState: any) => ({
                          ...prevState,
                          to: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="grid-d grid-w">
                    <span className="txt-label-sm">Date Formate:</span>
                    <select
                      className="input--type__text_no_border p-all0"
                      disabled
                      value={selectedFiltersDropdownObject.dateformate}
                      onChange={(e) =>
                        setSelectedFiltersDropdownObject((prevState: any) => ({
                          ...prevState,
                          dateformate: e.target.value,
                        }))
                      }
                    >
                      <option value={1}>UK</option>
                      <option value={2}>US</option>
                    </select>
                  </div>
                </div>

                <div className="reuse-min-wid">
                  {reportGridData && (
                    <TableGridView
                      GridData={reportGridData}
                      DateFormate={selectedFiltersDropdownObject.dateformate}
                    />
                  )}
                </div>
              </>
            )}

            {showReportGrid == true && reportGridData.length == 0 && (
              <>No data found.</>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Reporting;
