import { Link, NavLink } from "react-router-dom";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import AppContext from "../Context/Context";
import DragDrop from "../DragDrop/DragDrop";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import Notification from "./Notification";
import axiosAuth from "../axios/Interceptors";
import { Modal } from "react-bootstrap";

type Props = {
  setShowSupport: Function;
};
export default function Support(props: Props) {
  const globalState = useGlobalState();

  const [supportTicketData, setSupportTicketData] = useState<any>([]);
  const [showTicketDropDown, setShowTicketDropDown] = useState(false);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);
  const [selectedColor, setselectedColor] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [isLoggedInUser, setIsLoggedInUser] = useState(true);

  const [customDropDownObj, setCustomDropDownObj] = useState({
    MGSupportTypeID: 0,
    MGWorkspaceID: 0,
    MGApplicationID: 0,
    MGSupportCategoryID: 0,
    Name: "",
    Summary: "",
    Icon: "",
    AssignedToEmails: "",
  });

  const [supportObject, setSupportObject] = useState({
    RaisedForID: 0,
    RaisedByFirstName: "",
    RaisedByEmail: "",
    RaisedByPhone: "",
    MGApplicationPriorityID: 0,
    Summary: "",
    Description: "",
  });

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 1111,
    ObjectType: 1111,
    MaxAllowedFiles: 5,
    UploadType: 1,
    FolderName: "Support",
    PTUploadedFiles: [],
    //  MaxSize: 10,
  });

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  useEffect(() => {
    getDataForSupportTicket();
  }, []);

  function getDataForSupportTicket() {
    let object = {} as any;
    object.mgworkspaceid = 1;
    object.mgapplicationid = 1;
    object.mgsupportcategoryid = 1;
    setLoadingState(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/MGSupportTicket/GetDataForSupportTicket",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          if (l_response && l_response[0] && l_response[0][0]) {
            setCustomDropDownObj({
              MGSupportTypeID: l_response[0][0].MGSupportTypeID,
              MGWorkspaceID: l_response[0][0].MGWorkspaceID,
              MGApplicationID: l_response[0][0].MGApplicationID,
              MGSupportCategoryID: l_response[0][0].MGSupportCategoryID,
              Name: l_response[0][0].Name,
              Summary: l_response[0][0].Summary,
              Icon: l_response[0][0].Icon,
              AssignedToEmails: l_response[0][0].AssignedToEmails,
            });
          }
          setSupportTicketData(l_response);
          setLoadingState(false);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function checkValidation(takeAction: boolean) {
    // if (supportObject.SiteUserID == 0) {
    //   globalState.setNotificationObject({
    //     ShowPopup: true,
    //     Heading: "Alert",
    //     Message: "Please select request behalf of",
    //   });
    // } else
    if (supportObject.MGApplicationPriorityID == 0) {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Please select priority",
      });
    } else if (
      supportObject.RaisedByFirstName == "" &&
      isLoggedInUser == false
    ) {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Please provide name",
      });
    } else if (supportObject.RaisedByEmail == "" && isLoggedInUser == false) {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Please provide email",
      });
    } else if (supportObject.Summary == "") {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Please provide summary",
      });
    } else if (supportObject.Description == "") {
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Please provide Description",
      });
    } else {
      SaveFiles();
    }
  }

  function clearObject() {
    setSupportObject({
      RaisedForID: 0,
      RaisedByFirstName: "",
      RaisedByEmail: "",
      RaisedByPhone: "",
      MGApplicationPriorityID: 0,
      Summary: "",
      Description: "",
    });
  }

  function SaveFiles() {
    setLoadingState(true);
    if (uploadFileList && uploadFileList.length > 0) {
      let object = {} as any;
      object.typeid = 1;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = AppContext.App;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(AppContext.FilesAPI + "api/PTAttachment/SaveFilesData", object)
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            supportTicketSaveCustom(l_response);
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      supportTicketSaveCustom([]);
    }
  }

  function supportTicketSaveCustom(p_S3UploadFileList: any) {
    setLoadingState(true);
    let object = {} as any;
    object.assingedUseremail = customDropDownObj.AssignedToEmails;
    object.mgsupporttypeid = customDropDownObj.MGSupportTypeID;
    object.mgworkspaceid = customDropDownObj.MGWorkspaceID;
    object.mgapplicationid = customDropDownObj.MGApplicationID;
    object.mgapplicationpriorityid = supportObject.MGApplicationPriorityID;
    object.summary = supportObject.Summary;
    object.description = supportObject.Description;
    object.raisedforid = supportObject.RaisedForID;

    object.raisedbyfirstname = supportObject.RaisedByFirstName;
    object.raisedbyemail = supportObject.RaisedByEmail;
    object.raisedbyphone = supportObject.RaisedByPhone;

    object.filesdata = p_S3UploadFileList;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/MGSupportTicket/MGSupportTicketSaveCustom",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
        }
        clearObject();
        setLoadingState(false);
        props.setShowSupport(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoadingState(false);
      });
  }

  function NotificationCallBack() {
    globalState.setNotificationObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
    });
  }

  function setDataFromDropdown(item: any) {
    setCustomDropDownObj({
      MGSupportTypeID: item.MGSupportTypeID,
      MGWorkspaceID: item.MGWorkspaceID,
      MGApplicationID: item.MGApplicationID,
      MGSupportCategoryID: item.MGSupportCategoryID,
      Name: item.Name,
      Summary: item.Summary,
      Icon: item.Icon,
      AssignedToEmails: item.AssignedToEmails,
    });

    setShowTicketDropDown(false);
  }

  function getPriorityColor(id: any) {
    let color = "";

    for (let index = 0; index < supportTicketData[2].length; index++) {
      if (id == supportTicketData[2][index].MGApplicationPriorityID) {
        color = supportTicketData[2][index].Color;
        break;
      }
    }

    setselectedColor(color);
  }

  return (
    <>
      <Notification
        NotificationObject={globalState.NotificationObject}
        NotificationCallBack={NotificationCallBack}
      />
      {loadingState && <LoadingAnimation />}

      <Modal show={true}>
        <Modal.Header closeButton onClick={() => props.setShowSupport(false)}>
          <Modal.Title>Support Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="grid-d flex-gap15">
            <div className="grid-d flex-gap3 p-relative">
              <span className="bold-font txt-grey">
                What can we help you with?
              </span>
              <div
                className="flex-d flex-ac flex-gap10 grey-box-support"
                onClick={() => setShowTicketDropDown(!showTicketDropDown)}
              >
                <div className="flex-d flex-ac flex-jsb w-100">
                  <div className="flex-d flex-ac flex-gap5 w-100">
                    <a>
                      <img
                        // src={"/images/" + customDropDownObj.Icon}
                        src="images/gear.svg"
                        width="20px"
                        height="20"
                      />
                    </a>
                    <div className="grid-d">
                      <span className="flex-d txt-blue bold-font">
                        {customDropDownObj.Name}
                      </span>
                      <span className="txt-grey">
                        {customDropDownObj.Summary}
                      </span>
                    </div>
                  </div>
                  <img
                    src="images/chev-lib.svg"
                    className="sup-chev"
                    width={8}
                    height={4}
                  />
                </div>
              </div>

              {showTicketDropDown && (
                <div className="grid-d flex-gap10 grey-box-support p-absolute grey-box-drop">
                  {supportTicketData[0] &&
                    supportTicketData[0].map((Item: any, index: any) => (
                      <div
                        key={index}
                        onClick={() => setDataFromDropdown(Item)}
                        className="flex-d flex-ac flex-gap5"
                      >
                        <a>
                          <img
                            // src={"/images/" + Item.Icon}
                            src="images/gear.svg"
                            width="20px"
                            height="20"
                          />
                        </a>
                        <div className="grid-d">
                          <span className="flex-d txt-blue bold-font">
                            {Item.Name}
                          </span>
                          <span className="txt-grey">{Item.Summary}</span>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>

               {isLoggedInUser == true &&<div className="grid-d flex-gap3 p-relative">
              <span className="bold-font txt-grey">
                Raise this request on behalf of
              </span>
              <select
                className="input--type__text support-drop"
                defaultValue={supportObject.RaisedForID}
                onChange={(e) =>
                  setSupportObject((prevState: any) => ({
                    ...prevState,
                    RaisedForID: e.target.value,
                  }))
                }
              >
                <option value="">Select</option>
                {supportTicketData[1] &&
                  supportTicketData[1].map((Item: any, index: any) => (
                    <option value={Item.SiteUserID} key={Item.SiteUserID}>
                      {Item.Name}
                    </option>
                  ))}
              </select>
            </div>}
            {isLoggedInUser == false && (
              <>
                <div className="grid-d flex-gap3 p-relative">
                  <span className="bold-font txt-grey">Name</span>
                  <input
                    className="input--type__text sup-input"
                    value={supportObject.RaisedByFirstName}
                    onChange={(e) =>
                      setSupportObject((prevState: any) => ({
                        ...prevState,
                        RaisedByFirstName: e.target.value,
                      }))
                    }
                    type="text"
                  />
                </div>
                <div className="grid-d flex-gap3 p-relative">
                  <span className="bold-font txt-grey">Email</span>
                  <input
                    className="input--type__text sup-input"
                    value={supportObject.RaisedByEmail}
                    onChange={(e) =>
                      setSupportObject((prevState: any) => ({
                        ...prevState,
                        RaisedByEmail: e.target.value,
                      }))
                    }
                    type="text"
                  />
                </div>
                <div className="grid-d flex-gap3 p-relative">
                  <span className="bold-font txt-grey">Phone</span>
                  <input
                    className="input--type__text sup-input"
                    value={supportObject.RaisedByPhone}
                    onChange={(e) =>
                      setSupportObject((prevState: any) => ({
                        ...prevState,
                        RaisedByPhone: e.target.value,
                      }))
                    }
                    type="text"
                  />
                </div>
              </>
            )}
            <div className="grid-d flex-gap3 p-relative">
              <span className="bold-font txt-grey">Priority</span>
              <select
                className="input--type__text  support-drop"
                defaultValue={supportObject.MGApplicationPriorityID}
                onChange={(e) => {
                  setSupportObject((prevState: any) => ({
                    ...prevState,
                    MGApplicationPriorityID: e.target.value,
                  }));
                  getPriorityColor(e.target.value);
                }}
              >
                <option value="">Select</option>
                {supportTicketData[2] &&
                  supportTicketData[2].map((Item: any, index: any) => (
                    <option
                      value={Item.MGApplicationPriorityID}
                      key={Item.MGApplicationPriorityID}
                    >
                      {Item.Name}
                    </option>
                  ))}
              </select>
              <span
                className="priority-box flex-d p-absolute p-rt"
                style={{ background: selectedColor }}
              ></span>
            </div>

            <div className="grid-d flex-gap3 p-relative">
              <span className="bold-font txt-grey">Summary</span>
              <input
                className="input--type__text sup-input"
                value={supportObject.Summary}
                onChange={(e) =>
                  setSupportObject((prevState: any) => ({
                    ...prevState,
                    Summary: e.target.value,
                  }))
                }
                type="text"
              />
            </div>
            <div className="grid-d flex-gap3 support-area">
              <span className="bold-font txt-grey">Description</span>
              <textarea
                className="input--type__text input--type__textarea  sup-input"
                value={supportObject.Description}
                onChange={(e) =>
                  setSupportObject((prevState: any) => ({
                    ...prevState,
                    Description: e.target.value,
                  }))
                }
              ></textarea>
            </div>
            <div className="grid-d flex-gap3 support-attach">
              <span className="bold-font txt-grey">Attachment</span>
              <div className="drad-drop supp-r-img">
                <DragDrop
                  ParentFeatures={dragDropFeatures}
                  DragDropCallback={handleDragDropCallback}
                  TriggerChild={triggerChild}
                />
              </div>
            </div>
          </div>
          <div className="modal--button del--modal__btn">
            <a onClick={() => checkValidation(true)}>Send</a>
            <a className="cancel" onClick={() => props.setShowSupport(false)}>
              Cancel
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
