import React, { useState, useEffect, useRef } from "react";
import { HubConnectionBuilder, HubConnection } from "@microsoft/signalr";
import Picker from "emoji-picker-react";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import axiosAuth from "../../axios/Interceptors";
import AppContext from "../../Context/Context";
import LoadingAnimation from "../../LoadingAnimation/LoadingAnimation";
import DragDrop from "../../DragDrop/DragDrop";

type Props = {
  workspaceid: any;
  chennelid: any;
};

export default function TasksComm(props: Props) {
  const [LoadingState, SetLoadingState] = useState(false);

  const params = useParams();
  const Navigate = useNavigate();

  let workspaceid = props.workspaceid;
  let chennelid = props.chennelid;

  const [myidentifier, Setmyidentifier] = useState("");
  const [myidentifierreaction, Setmyidentifierreaction] = useState("");

  const myidentifierRef: any = useRef(null);
  myidentifierRef.current = myidentifier;

  const myidentifierreactionRef: any = useRef(null);
  myidentifierreactionRef.current = myidentifierreaction;

  const chennelidRef: any = useRef(null);
  chennelidRef.current = chennelid;

  const [MessageCategoryData, SetMessageCategory] = useState<any>([]);
  const [MessageChannelData, SetMessageChannel] = useState<any>([]);
  const [MessageReactions, SetMessageReactions] = useState<any>([]);
  let [MyChannelsMessagesDataByDate, SetMyChannelsMessagesByDate] =
    useState<any>([]);

  const MyChannelsMessagesDataByDateRef: any = useRef(null);

  MyChannelsMessagesDataByDateRef.current = MyChannelsMessagesDataByDate;

  const [EditedMessage, SetEditedMessage] = useState<any>({
    MessageID: 0,
    Content: "",
    ChannelID: "",
  });

  const [SelectedChennelName, SetSelectedChennelName] = useState("");

  const [UserMessage, SetUserMessage] = useState("");
  const [SelectedMGMessageCategoryID, SetSelectedMGMessageCategoryID] =
    useState(0);
  const [MessageChannelID, SetMessageChannelID] = useState(0);

  const [MessageIdToBeDeleted, SetMessageIdToBeDeleted] = useState(0);
  const [ShowEmoji, SetShowEmoji] = useState(false);

  const [ShowAttachemtButton, SetShowAttachemtButton] = useState(false);
  const [ShowDragDrop, SetShowDragDrop] = useState(false);

  const [ShowHide, SetShowHide] = useState(false);
  const [MyChatsShowHide, MyChatsSetShowHide] = useState(false);

  const [NeedToRedirect, SetNeedToRedirect] = useState(false);

  const [NeedToRefesh, SetToRefesh] = useState(true);

  const [UpdateChatMessage, SetUpdateChatMessage] = useState(false);

  const [MyChannelsMessagesData, SetMyChannelsMessages] = useState<any>([]);
  const [MyChannelsMessagesAttachments, SetMyChannelsMessagesAttachments] =
    useState<any>([]);
  const [MyChannelsMessagesReactions, SetMyChannelsMessagesReactions] =
    useState<any>([]);

  const latestChat: any = useRef(null);

  latestChat.current = MyChannelsMessagesData;

  const LatestMessageID: any = useRef(null);
  LatestMessageID.current = MessageChannelID;

  const [MyChannelsMessagesDataForThreads, SetMyChannelsMessagesForThreads] =
    useState<any>([]);
  const [
    MyChannelsMessagesAttachmentsForThreads,
    SetMyChannelsMessagesAttachmentsForThreads,
  ] = useState<any>([]);
  const [
    MyChannelsMessagesReactionsForThreads,
    SetMyChannelsMessagesReactionsForThreads,
  ] = useState<any>([]);
  let [
    MyChannelsMessagesDataByDateForThreads,
    SetMyChannelsMessagesByDateForThreads,
  ] = useState([]);
  const [UserMessageForThreads, SetUserMessageForThreads] = useState("");
  const [ShowEmojiForThreads, SetShowEmojiForThreads] = useState(false);

  const [ThreadMessageObject, SetThreadMessageObject] = useState<any>({
    MGMessageID: 0,
  });

  const ThreadMessageObjectRef: any = useRef(null);
  ThreadMessageObjectRef.current = ThreadMessageObject;

  const latestChatForThreads: any = useRef(null);
  latestChatForThreads.current = MyChannelsMessagesDataForThreads;

  const [ScrollToBottomForThreads, SetScrollToBottomForThreads] =
    useState(false);
  const ScrollToBottomRefForThreads: any = useRef(null);
  ScrollToBottomRefForThreads.current = ScrollToBottomForThreads;

  const bottomRefForThreads: any = useRef(null);
  useEffect(() => {
    bottomRefForThreads.current?.scrollIntoView({ behavior: "smooth" });
  }, [ScrollToBottomForThreads]);

  const MyChannelsMessagesDataByDateForThreadsRef: any = useRef(null);

  MyChannelsMessagesDataByDateForThreadsRef.current =
    MyChannelsMessagesDataByDateForThreads;

  const [EditedMessageForThreads, SetEditedMessageForThreads] = useState<any>({
    MessageID: 0,
    Content: "",
    ChannelID: "",
  });

  const [ShowAttachemtButtonForThreads, SetShowAttachemtButtonForThreads] =
    useState(false);
  const [ShowDragDropForThreads, SetShowDragDropForThreads] = useState(false);

  const [ShowCategoryModal, SetShowCategoryModal] = useState(false);
  const [ShowChannelModal, SetShowChannelModal] = useState(false);
  const [ShowConfirmationModal, SetShowConfirmationModal] = useState(false);

  const [ScrollToBottom, SetScrollToBottom] = useState(false);
  const ScrollToBottomRef: any = useRef(null);
  ScrollToBottomRef.current = ScrollToBottom;

  const [CategoryObject, SetCategoryObject] = useState<any>({
    Name: "",
    Description: "",
  });

  const [ChannelObject, SetChannelObject] = useState<any>({
    CategoryId: 0,
    Name: "",
    Description: "",
  });
  const [Showeditdrop, SetShoweditdrop] = useState(false);
  const ShowHideeditdrop = () => {
    SetShoweditdrop(!Showeditdrop);
  };

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
    LoadCommInit();
  }, [NeedToRefesh, props.chennelid, props.workspaceid]);

  useEffect(() => {
    GetMyCommChannels();
  }, [props.chennelid, props.workspaceid]);

  function showNotification(p_strMessgae: any) {
    const n = new Notification(p_strMessgae);

    //n.close();
    setTimeout(() => {
      n.close();
    }, 3000);

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        // The tab has become visible so clear the now-stale Notification.
        n.close();
      }
    });
  }

  function ShowHideCategoryModal() {
    SetShowCategoryModal(!ShowCategoryModal);
    SetShowChannelModal(false);
    ShowHideCategoryPopUp(-1);
  }

  function ShowHideChannelModal() {
    SetShowChannelModal(!ShowChannelModal);
    SetShowCategoryModal(false);
    ShowHideCategoryPopUp(-1);
  }

  function LoadCommInit() {
    let LoadedWorkSpaceid = "";
    let LoadedChennelid = "";
    if (workspaceid && workspaceid !== null && workspaceid !== "") {
      LoadedWorkSpaceid = workspaceid;
    }

    if (chennelid && chennelid !== null && chennelid !== "") {
      LoadedChennelid = chennelid;
    }

    chennelid = LoadedChennelid;
    workspaceid = LoadedWorkSpaceid;

    SetDragDropFeatures((prevState) => ({
      ...prevState,
      OtherParams: workspaceid + "," + chennelid,
    }));

    if (LoadedWorkSpaceid === "") {
      SetNeedToRedirect(true);

      var a = NeedToRedirect;
      GetMyWorkSpaces();
    } else {
      if (LoadedChennelid === "") {
        SetNeedToRedirect(true);
      }
      GetMyCommChannels();
    }
  }

  function GetMyWorkSpaces() {
    let object = {};
    axiosAuth
      .post(
        "https://authapi.manyge.com/api/MGWorkspace/GetMyWorkSpaces",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var l_GridData = response.data.Data[0];

          AppContext.SetLocalStorageObject("WorkSpaceList", l_GridData);

          let SelectedWorkSpaceid = "";
          for (let i = 0; i < l_GridData.length; i++) {
            if (l_GridData[i].Selected) {
              AppContext.SetLocalStorageObject(
                "SelectedWorkSpace",
                l_GridData[i]
              );
              SelectedWorkSpaceid = l_GridData[i].Code;
            }
          }
          if (SelectedWorkSpaceid === "") {
            if (l_GridData.length > 0) {
              AppContext.SetMyWorkSpaces(l_GridData[0].MGWorkspaceID);
              AppContext.SetLocalStorageObject(
                "SelectedWorkSpace",
                l_GridData[0]
              );
              SelectedWorkSpaceid = l_GridData[0].Code;
            }
          }
          workspaceid = SelectedWorkSpaceid;

          GetMyCommChannels();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function ShowSelectedChannel(
    p_arrMessageCategoryData: any,
    p_arrMessageChannel: any
  ) {
    for (let i = 0; i < p_arrMessageCategoryData.length; i++) {
      for (let j = 0; j < p_arrMessageChannel.length; j++) {
        if (
          p_arrMessageCategoryData[i].MGMessageCategoryID ===
            p_arrMessageChannel[j].MGMessageCategoryID &&
          p_arrMessageChannel[j].Code === chennelid
        ) {
          SetSelectedMGMessageCategoryID(
            p_arrMessageCategoryData[i].MGMessageCategoryID
          );
          p_arrMessageCategoryData[i].hidden = true;
        }
      }
    }
    return p_arrMessageCategoryData;
  }

  function UpdateSelectedMGMessageCategoryID(SelectedMGMessageCategoryID: any) {
    SetSelectedMGMessageCategoryID(SelectedMGMessageCategoryID);
  }

  function ShowHideCategory(p_index: any) {
    MessageCategoryData[p_index].hidden = !MessageCategoryData[p_index].hidden;
    SetShowHide(!ShowHide);
  }

  function ShowHideCategoryPopUp(p_index: any) {
    for (let i = 0; i < MessageCategoryData.length; i++) {
      if (i === p_index) {
        MessageCategoryData[p_index].hiddenPopUp =
          !MessageCategoryData[p_index].hiddenPopUp;
        SetShowHide(!ShowHide);
      } else {
        MessageCategoryData[i].hiddenPopUp = false;
      }
    }
  }

  function CheckClickEvent(e: any) {
    var l_ImageAlt = e.target.alt;
    var l_ClassName = e.target.className;
    if (!l_ImageAlt && l_ImageAlt !== "ShowHideImg") {
      ShowHideCategoryPopUp(-1);
      SetShowHide(!ShowHide);
    }
    //console.log(l_ClassName);
    if (
      !l_ImageAlt &&
      l_ImageAlt !== "smiley" &&
      l_ClassName !== "emoji-img" &&
      l_ClassName !== "icn-animals_nature" &&
      l_ClassName !== "icn-food_drink" &&
      l_ClassName !== "icn-smileys_people" &&
      l_ClassName !== "icn-travel_places" &&
      l_ClassName !== "icn-activities" &&
      l_ClassName !== "icn-objects" &&
      l_ClassName !== "icn-symbols" &&
      l_ClassName !== "icn-flags" &&
      l_ClassName !== "emoji-group"
    ) {
      SetShowEmoji(false);
    }
  }

  function ShowHideMyChats() {
    MyChatsSetShowHide(!MyChatsShowHide);
  }

  function GetMyCommChannels() {
    let object: any = {};
    object.mgworkspaceid = props.workspaceid;
    axiosAuth
      .post(
        "https://commapi.manyge.com/api/MGMessage/GetMyCommChannels",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var l_MessageCategory = response.data.Data[0];
          var l_MessageChannel = response.data.Data[1];
          var l_MessageReactions = response.data.Data[2];
          var l_SelectedMessageCategory = ShowSelectedChannel(
            l_MessageCategory,
            l_MessageChannel
          );
          SetMessageCategory(l_SelectedMessageCategory);
          SetMessageChannel(l_MessageChannel);
          SetMessageReactions(l_MessageReactions);
          let SelectedChennelid = chennelid;
          GetMyChannelsMessages(SelectedChennelid, l_MessageChannel, true);
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function NavigateMessageChennel(
    p_SelectedChennelid: any,
    p_MGMessageCategoryID: any
  ) {
    // SetToRefesh(!NeedToRefesh)
    SetSelectedMGMessageCategoryID(p_MGMessageCategoryID);
    Navigate("/comm/" + workspaceid + "/" + p_SelectedChennelid);
    var l_MessageChannel: any = [];
    chennelid = p_SelectedChennelid;
    SetDragDropFeatures((prevState) => ({
      ...prevState,
      OtherParams: workspaceid + "," + chennelid,
    }));
    GetMyChannelsMessages(p_SelectedChennelid, l_MessageChannel, false);
  }

  function GetMyChannelsMessages(
    p_SelectedChennelid: any,
    p_MessageChannel: any,
    p_IsRefresh: any
  ) {
    let ChennelPKid = 0;
    if (p_IsRefresh) {
      for (let i = 0; i < p_MessageChannel.length; i++) {
        if (p_MessageChannel[i].Code === p_SelectedChennelid) {
          SetSelectedChennelName(p_MessageChannel[i].Title);
          ChennelPKid = p_MessageChannel[i].MGMessageChannelID;
        }
      }
    } else {
      for (let i = 0; i < MessageChannelData.length; i++) {
        if (MessageChannelData[i].Code === p_SelectedChennelid) {
          SetSelectedChennelName(MessageChannelData[i].Title);
          ChennelPKid = MessageChannelData[i].MGMessageChannelID;
        }
      }
    }

    SetDragDropFeatures((prevState) => ({
      ...prevState,
      ObjectID: ChennelPKid,
    }));

    SetMessageChannelID(ChennelPKid);
    let object: any = {};
    //object.mgmessagechannelid = ChennelPKid;
    object.channelid = chennelid;
    object.originalmessageid = 0;
    axiosAuth
      .post(
        "https://commapi.manyge.com/api/MGMessage/GetMyChannelsMessages",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var l_MyChannelsMessages = response.data.Data[0];
          var l_MyChannelsAttachments = response.data.Data[1];
          var l_MessageReactions = response.data.Data[2];
          let AttachmentArray = [];
          let ReactionArray = [];
          for (let i = 0; i < l_MyChannelsMessages.length; i++) {
            AttachmentArray = [];
            ReactionArray = [];

            for (let j = 0; j < l_MyChannelsAttachments.length; j++) {
              if (
                l_MyChannelsMessages[i].MGMessageID ===
                l_MyChannelsAttachments[j].MGMessageID
              ) {
                let Attachmentobject: any = {};
                Attachmentobject.FileURL =
                  AppContext.GetCommFiles +
                  workspaceid +
                  "/" +
                  chennelid +
                  "/" +
                  l_MyChannelsAttachments[j].Identifier +
                  "/" +
                  l_MyChannelsAttachments[j].Name;
                Attachmentobject.FileIconToDisplay =
                  AppContext.GetFileIconToDisplay(
                    AppContext.GetCommFiles +
                      workspaceid +
                      "/" +
                      chennelid +
                      "/" +
                      l_MyChannelsAttachments[j].Identifier +
                      "/" +
                      l_MyChannelsAttachments[j].Name
                  ); //AppContext.GetFileIconToDisplay(l_MyChannelsAttachments[j].FileURLS3);
                Attachmentobject.Name = l_MyChannelsAttachments[j].Name;
                AttachmentArray.push(Attachmentobject);
              }
            }

            for (let j = 0; j < l_MessageReactions.length; j++) {
              if (
                l_MyChannelsMessages[i].MGMessageID ===
                l_MessageReactions[j].MGMessageID
              ) {
                ReactionArray.push(l_MessageReactions[j]);
              }
            }

            l_MyChannelsMessages[i].MessageAttachment = AttachmentArray;
            l_MyChannelsMessages[i].MessageReactions = ReactionArray;
          }

          SetMyChannelsMessages(l_MyChannelsMessages);
          SetMyChannelsMessagesAttachments(l_MyChannelsAttachments);
          SetMyChannelsMessagesReactions(l_MessageReactions);

          let l_ScrollToBottom = ScrollToBottomRef.current;
          SetScrollToBottom(!l_ScrollToBottom);
          MadeGroupByDate(l_MyChannelsMessages);
          AppContext.SetMySelectedChannel(chennelid);
          SetUserMessage("");
          HandleEditMessage();
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  const onEmojiClick = (event: any, emojiObject: any) => {
    SetUserMessage(`${UserMessage}${emojiObject.emoji}`);
  };

  const ShowHidePicker = () => {
    SetShowEmoji(!ShowEmoji);
  };

  const ShowHidePickerForThreads = () => {
    SetShowEmojiForThreads(!ShowEmojiForThreads);
  };

  function ChangeHandler(e: any) {
    SetUserMessage(e.target.value);
  }
  const bottomRef: any = useRef(null);
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [ScrollToBottom]);

  function PushMessageLocally() {
    let object: any = {};
    object.MGMessageID = -1;
    object.Content = UserMessage;
    object.MessageChannelID = MessageChannelID;
    object.MessageTime = new Date();
    SetMyChannelsMessages([...MyChannelsMessagesData, object]);
  }

  function SaveMessage() {
    if (EditedMessage.MessageID > 0 && EditedMessage.ChannelID === chennelid) {
      UpdateMessage();
    } else {
      if (UploadFileList && UploadFileList.length > 0) {
        SaveFiles();
      } else {
        let object: any = [];
        SaveMyMessage(object);
      }
    }
  }
  function UpdateMessage() {
    if (UserMessage && UserMessage != "") {
      let object: any = {};
      object.mgmessageid = EditedMessage.MessageID;
      object.channelid = EditedMessage.ChannelID;
      object.content = UserMessage;
      SetUserMessage("");
      axiosAuth
        .post(AppContext.AppBaseURL + "api/MGMessage/UpdateMessage", object)
        .then((response) => {
          if (response.data.Status === 1) {
            CancelEditMyMessage();
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
        })

        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      AppContext.ShowNotification("error", "Please Enter Message");
    }
  }

  function SaveMyMessage(filesdata: any) {
    if (UserMessage && UserMessage != "") {
      //PushMessageLocally();
      let l_strmyidentifier = AppContext.GenerateUniqueGUID();
      Setmyidentifier(l_strmyidentifier);
      let object: any = {};
      //object.mgmessagechannelid = MessageChannelID;
      object.myidentifier = l_strmyidentifier;
      object.channelid = chennelid;
      object.content = UserMessage;
      object.filesdata = filesdata;
      SetUserMessage("");
      axiosAuth
        .post(AppContext.AppBaseURL + "api/MGMessage/AddMessage", object)
        .then((response) => {
          if (response.data.Status === 1) {
            //GetMyChannelsMessages(MessageChannelID);
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
        })

        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      AppContext.ShowNotification("error", "Please Enter Message");
    }
  }

  function StartSignalRConnection() {
    const connection = new HubConnectionBuilder()
      .withUrl(AppContext.AppBaseURL + "hubs/chat")
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then((result: any) => {
        console.log("Connected!");
        AppContext.SignalRConnectionID = connection.connectionId;
        SubscribeConnectionToChannel(2);

        connection.on("ReceiveMessage", (message: any) => {
          if (message && message.datatypeid === 1) {
            // when a new message is added.
            HandleSignalRData(message);
          } else if (message && message.datatypeid === 2) {
            // when a message is edited.
            HandleSignalRMessageEditedData(message);
          } else if (message && message.datatypeid === 3) {
            // when a message is deleted.
            HandleSignalRMessageDeleteData(message);
          } else if (message && message.datatypeid === 4) {
            // when a reaction is added.
            HandleSignalRMessageReaction(message);
          }
        });
      })
      .catch((e: any) => console.log("Connection failed: ", e));
  }

  useEffect(() => {
    StartSignalRConnection();
  }, [MessageChannelID]);

  useEffect(() => {
    SubscribeConnectionToChannel(1);
  }, [MessageChannelID]);

  function SubscribeConnectionToChannel(p_intType: any) {
    let UpdatedMessageID = LatestMessageID.current;
    let object: any = {};
    object.mgmessagechannelid = UpdatedMessageID;
    object.signalrconnectionid = AppContext.SignalRConnectionID;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/CommMain/SubscribeConnectionToChannel",
        object
      )
      .then((response) => {})

      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function HandleSignalRData(p_oData: any) {
    let object: any = {};
    object.MGMessageID = p_oData.mgmessageid;
    object.SiteUserID = p_oData.siteuserid;
    object.Title = p_oData.title;
    object.Content = p_oData.content;
    object.Status = p_oData.status;
    object.HasReply = p_oData.hasreply;
    object.OriginalMessageID = p_oData.originalmessageid;
    object.ShowInChannel = p_oData.showinchannel;
    object.MessageType = p_oData.messagetype;
    object.AssociatedObjectType = p_oData.associatedobjecttype;
    object.IsEdited = p_oData.isedited;
    object.MessageTime = p_oData.messagetime;
    object.UserName = p_oData.username;
    object.ImageURL = p_oData.imageurl;
    object.ChannelId = p_oData.channelid;
    object.ReplyCount = p_oData.replycount;
    object.LastReplyTime = p_oData.lastreplytime;
    object.LastReplyContents = p_oData.lastreplycontents;
    if (p_oData.myidentifier === myidentifierRef.current) {
      object.PermType = 1;
    } else {
      object.PermType = 0;
    }

    if (p_oData.usernameinitials.length > 2) {
      let UNInitial = p_oData.usernameinitials.split(" ");
      if (UNInitial.length > 1) {
        object.UserNameInitials =
          UNInitial[0].substring(0, 1) + "" + UNInitial[1].substring(0, 1);
      }
    } else {
      object.UserNameInitials = p_oData.usernameinitials;
    }

    if (object.OriginalMessageID > 0) {
      //&& object.OriginalMessageID === ThreadMessageObject.MGMessageID

      var MessageFilesData = [];
      let AttachmentArray = [];
      if (
        p_oData.messagefiles &&
        p_oData.messagefiles != null &&
        p_oData.messagefiles.length > 0
      ) {
        MessageFilesData = p_oData.messagefiles;
        for (let i = 0; i < MessageFilesData.length; i++) {
          let Attachmentobject: any = {};
          Attachmentobject.FileURL =
            AppContext.GetCommFiles +
            workspaceid +
            "/" +
            p_oData.channelid +
            "/" +
            MessageFilesData[i].identifier +
            "/" +
            MessageFilesData[i].name;
          Attachmentobject.FileIconToDisplay = AppContext.GetFileIconToDisplay(
            AppContext.GetCommFiles +
              workspaceid +
              "/" +
              p_oData.channelid +
              "/" +
              MessageFilesData[i].identifier +
              "/" +
              MessageFilesData[i].name
          );
          Attachmentobject.Name = MessageFilesData[i].name;
          AttachmentArray.push(Attachmentobject);
          object.MessageAttachment = AttachmentArray;
        }
      }

      let NeedToPush = true;
      for (let i = 0; i < latestChatForThreads.current.length; i++) {
        if (
          latestChatForThreads.current[i].MGMessageID === object.MGMessageID
        ) {
          NeedToPush = false;
          break;
        }
      }

      if (chennelidRef.current === object.ChannelId && NeedToPush) {
        const updatedChat: any = [...latestChatForThreads.current];
        updatedChat.push(object);
        SetMyChannelsMessagesForThreads(updatedChat);
        let l_ScrollToBottom = ScrollToBottomRefForThreads.current;
        SetScrollToBottomForThreads(!l_ScrollToBottom);
        MadeGroupByDateForThreads(updatedChat);

        if (p_oData.channelid === chennelidRef.current) {
          let replycount = ThreadMessageObjectRef.current.ReplyCount + 1;
          for (
            let i = 0;
            i < MyChannelsMessagesDataByDateRef.current.length;
            i++
          ) {
            for (
              let j = 0;
              j < MyChannelsMessagesDataByDateRef.current[i].Messages.length;
              j++
            ) {
              if (
                MyChannelsMessagesDataByDateRef.current[i].Messages[j]
                  .MGMessageID === ThreadMessageObjectRef.current.MGMessageID
              ) {
                const updatedChat = [
                  ...MyChannelsMessagesDataByDateRef.current,
                ];
                updatedChat[i].Messages[j].ReplyCount =
                  ThreadMessageObjectRef.current.ReplyCount + 1;
                updatedChat[i].Messages[j].LastReplyTime = object.MessageTime;
                updatedChat[i].Messages[j].LastReplyContents = object.Content;
                SetMyChannelsMessagesByDate(updatedChat);
              }
            }
          }
        }
      }
    } else if (object.OriginalMessageID === 0) {
      var MessageFilesData = [];
      let AttachmentArray = [];
      if (
        p_oData.messagefiles &&
        p_oData.messagefiles != null &&
        p_oData.messagefiles.length > 0
      ) {
        MessageFilesData = p_oData.messagefiles;
        for (let i = 0; i < MessageFilesData.length; i++) {
          let Attachmentobject: any = {};
          Attachmentobject.FileURL =
            AppContext.GetCommFiles +
            workspaceid +
            "/" +
            p_oData.channelid +
            "/" +
            MessageFilesData[i].identifier +
            "/" +
            MessageFilesData[i].name;
          Attachmentobject.FileIconToDisplay = AppContext.GetFileIconToDisplay(
            AppContext.GetCommFiles +
              workspaceid +
              "/" +
              p_oData.channelid +
              "/" +
              MessageFilesData[i].identifier +
              "/" +
              MessageFilesData[i].name
          );
          Attachmentobject.Name = MessageFilesData[i].name;
          AttachmentArray.push(Attachmentobject);
          object.MessageAttachment = AttachmentArray;
        }
      }

      let NeedToPush = true;
      for (let i = 0; i < latestChat.current.length; i++) {
        if (latestChat.current[i].MGMessageID === object.MGMessageID) {
          NeedToPush = false;
          break;
        }
      }

      if (NeedToPush) {
        if (document.visibilityState !== "visible") {
          showNotification(object.Content);
        }
      }

      if (chennelidRef.current === object.ChannelId && NeedToPush) {
        const updatedChat = [...latestChat.current];
        updatedChat.push(object);
        SetMyChannelsMessages(updatedChat);
        let l_ScrollToBottom = ScrollToBottomRef.current;
        SetScrollToBottom(!l_ScrollToBottom);
        MadeGroupByDate(updatedChat);
      }
    }
  }

  function HandleSignalRMessageDeleteData(p_oData: any) {
    if (p_oData.channelid === chennelidRef.current) {
      if (p_oData.originalmessageid === 0) {
        for (let i = 0; i < latestChat.current.length; i++) {
          if (latestChat.current[i].MGMessageID === p_oData.mgmessageid) {
            latestChat.current.splice(i, 1);
            break;
          }
        }

        for (
          let i = 0;
          i < MyChannelsMessagesDataByDateRef.current.length;
          i++
        ) {
          for (
            let j = 0;
            j < MyChannelsMessagesDataByDateRef.current[i].Messages.length;
            j++
          ) {
            if (
              MyChannelsMessagesDataByDateRef.current[i].Messages[j]
                .MGMessageID === p_oData.mgmessageid
            ) {
              const updatedChat = [...MyChannelsMessagesDataByDateRef.current];
              updatedChat[i].Messages.splice(j, 1);
              SetMyChannelsMessagesByDate(updatedChat);
            }
          }
        }
      } else if (p_oData.originalmessageid > 0) {
        for (let i = 0; i < latestChatForThreads.current.length; i++) {
          if (
            latestChatForThreads.current[i].MGMessageID === p_oData.mgmessageid
          ) {
            latestChatForThreads.current.splice(i, 1);
            break;
          }
        }

        for (
          let i = 0;
          i < MyChannelsMessagesDataByDateForThreadsRef.current.length;
          i++
        ) {
          for (
            let j = 0;
            j <
            MyChannelsMessagesDataByDateForThreadsRef.current[i].Messages
              .length;
            j++
          ) {
            if (
              MyChannelsMessagesDataByDateForThreadsRef.current[i].Messages[j]
                .MGMessageID === p_oData.mgmessageid
            ) {
              const updatedChat: any = [
                ...MyChannelsMessagesDataByDateForThreadsRef.current,
              ];
              updatedChat[i].Messages.splice(j, 1);
              SetMyChannelsMessagesByDateForThreads(updatedChat);
            }
          }
        }
      }
    }
  }

  function HandleSignalRMessageEditedData(p_oData: any) {
    if (p_oData.channelid === chennelidRef.current) {
      if (p_oData.originalmessageid === 0) {
        for (
          let i = 0;
          i < MyChannelsMessagesDataByDateRef.current.length;
          i++
        ) {
          for (
            let j = 0;
            j < MyChannelsMessagesDataByDateRef.current[i].Messages.length;
            j++
          ) {
            if (
              MyChannelsMessagesDataByDateRef.current[i].Messages[j]
                .MGMessageID === p_oData.mgmessageid
            ) {
              const updatedChat = [...MyChannelsMessagesDataByDateRef.current];
              updatedChat[i].Messages[j].Content = p_oData.content;
              updatedChat[i].Messages[j].IsEdited = true;
              SetMyChannelsMessagesByDate(updatedChat);
            }
          }
        }
      } else if (p_oData.originalmessageid > 0) {
        for (
          let i = 0;
          i < MyChannelsMessagesDataByDateForThreadsRef.current.length;
          i++
        ) {
          for (
            let j = 0;
            j <
            MyChannelsMessagesDataByDateForThreadsRef.current[i].Messages
              .length;
            j++
          ) {
            if (
              MyChannelsMessagesDataByDateForThreadsRef.current[i].Messages[j]
                .MGMessageID === p_oData.mgmessageid
            ) {
              const updatedChat: any = [
                ...MyChannelsMessagesDataByDateForThreadsRef.current,
              ];
              updatedChat[i].Messages[j].Content = p_oData.content;
              updatedChat[i].Messages[j].IsEdited = true;
              SetMyChannelsMessagesByDateForThreads(updatedChat);
            }
          }
        }
      }
    }
  }

  function HandleSignalRMessageReaction(p_oData: any) {
    if (p_oData.channelid === chennelidRef.current) {
      if (p_oData.originalmessageid === 0) {
        for (
          let i = 0;
          i < MyChannelsMessagesDataByDateRef.current.length;
          i++
        ) {
          for (
            let j = 0;
            j < MyChannelsMessagesDataByDateRef.current[i].Messages.length;
            j++
          ) {
            if (
              MyChannelsMessagesDataByDateRef.current[i].Messages[j]
                .MGMessageID === p_oData.mgmessageid
            ) {
              if (p_oData.messagereaction[0].isdeleted) {
                //Now loop reaction list of that message and remove the reaction from the list
                //for(MessageList.MessageReaction){
                //if(MessageList.MessageReaction[i].MGMessageID === p_oData.messagereaction[0].mgmessageid && MessageList.MessageReaction[i].SiteUserID === p_oData.messagereaction[0].siteuserid)
                //Remove message
                for (
                  let k = 0;
                  k <
                  MyChannelsMessagesDataByDateRef.current[i].Messages[j]
                    .MessageReactions.length;
                  k++
                ) {
                  if (
                    MyChannelsMessagesDataByDateRef.current[i].Messages[j]
                      .MessageReactions[k].MGMessageID === p_oData.mgmessageid
                  ) {
                    for (let p = 0; p < p_oData.messagereaction.length; p++) {
                      if (
                        MyChannelsMessagesDataByDateRef.current[i].Messages[j]
                          .MessageReactions[k].SiteUserID ===
                          p_oData.messagereaction[p].siteuserid &&
                        MyChannelsMessagesDataByDateRef.current[i].Messages[j]
                          .MessageReactions[k].MGMessageInteractionID ===
                          p_oData.messagereaction[p].mgmessageinteractionid
                      ) {
                        const updatedChat = [
                          ...MyChannelsMessagesDataByDateRef.current,
                        ];
                        updatedChat[i].Messages[j].MessageReactions.splice(
                          k,
                          1
                        );
                        SetMyChannelsMessagesByDate(updatedChat);
                      }
                    }
                  }
                }
              } else {
                if (
                  p_oData.messagereaction[0].myidentifierreaction ===
                  myidentifierreactionRef.current
                ) {
                  p_oData.messagereaction[0].permtype = 1;
                }
                //Add this reaction to the reaction lift of that message
                //MessageList.MessageReaction.push(p_oData.messagereaction[0])
                const updatedChat = [
                  ...MyChannelsMessagesDataByDateRef.current,
                ];
                if (
                  updatedChat[i].Messages[j].MessageReactions === undefined ||
                  updatedChat[i].Messages[j].MessageReactions === null
                ) {
                  updatedChat[i].Messages[j].MessageReactions = [];
                }

                let object: any = {};
                object.Code = p_oData.messagereaction[0].code;
                object.ImageURL = p_oData.messagereaction[0].imageurl;
                object.PermType = p_oData.messagereaction[0].permtype;
                object.MGInteractionID =
                  p_oData.messagereaction[0].mginteractionid;
                object.SiteUserID = p_oData.messagereaction[0].siteuserid;
                object.Title = p_oData.messagereaction[0].title;
                object.UserName = p_oData.messagereaction[0].username;
                object.UserNameInitials =
                  p_oData.messagereaction[0].usernameinitials;
                object.MGMessageInteractionID =
                  p_oData.messagereaction[0].mgmessageinteractionid;
                object.MGMessageID = p_oData.mgmessageid;

                let NeedToPush = true;

                for (
                  let k = 0;
                  k <
                  MyChannelsMessagesDataByDateRef.current[i].Messages[j]
                    .MessageReactions.length;
                  k++
                ) {
                  if (
                    MyChannelsMessagesDataByDateRef.current[i].Messages[j]
                      .MessageReactions[k].MGMessageInteractionID ===
                    p_oData.messagereaction[0].mgmessageinteractionid
                  ) {
                    NeedToPush = false;
                    break;
                  }
                }

                if (NeedToPush) {
                  updatedChat[i].Messages[j].MessageReactions.push(object);
                  SetMyChannelsMessagesByDate(updatedChat);
                }
              }
            }
          }
        }
      } else if (p_oData.originalmessageid > 0) {
        for (
          let i = 0;
          i < MyChannelsMessagesDataByDateForThreadsRef.current.length;
          i++
        ) {
          for (
            let j = 0;
            j <
            MyChannelsMessagesDataByDateForThreadsRef.current[i].Messages
              .length;
            j++
          ) {
            if (
              MyChannelsMessagesDataByDateForThreadsRef.current[i].Messages[j]
                .MGMessageID === p_oData.mgmessageid
            ) {
              if (p_oData.messagereaction[0].isdeleted) {
                //Now loop reaction list of that message and remove the reaction from the list
                //for(MessageList.MessageReaction){
                //if(MessageList.MessageReaction[i].MGMessageID === p_oData.messagereaction[0].mgmessageid && MessageList.MessageReaction[i].SiteUserID === p_oData.messagereaction[0].siteuserid)
                //Remove message
                for (
                  let k = 0;
                  k <
                  MyChannelsMessagesDataByDateForThreadsRef.current[i].Messages[
                    j
                  ].MessageReactions.length;
                  k++
                ) {
                  if (
                    MyChannelsMessagesDataByDateForThreadsRef.current[i]
                      .Messages[j].MessageReactions[k].MGMessageID ===
                    p_oData.mgmessageid
                  ) {
                    for (let p = 0; p < p_oData.messagereaction.length; p++) {
                      if (
                        MyChannelsMessagesDataByDateForThreadsRef.current[i]
                          .Messages[j].MessageReactions[k].SiteUserID ===
                          p_oData.messagereaction[p].siteuserid &&
                        MyChannelsMessagesDataByDateForThreadsRef.current[i]
                          .Messages[j].MessageReactions[k]
                          .MGMessageInteractionID ===
                          p_oData.messagereaction[p].mgmessageinteractionid
                      ) {
                        const updatedChat: any = [
                          ...MyChannelsMessagesDataByDateForThreadsRef.current,
                        ];
                        updatedChat[i].Messages[j].MessageReactions.splice(
                          k,
                          1
                        );
                        SetMyChannelsMessagesByDateForThreads(updatedChat);
                      }
                    }
                  }
                }
              } else {
                if (
                  p_oData.messagereaction[0].myidentifierreaction ===
                  myidentifierreactionRef.current
                ) {
                  p_oData.messagereaction[0].permtype = 1;
                }
                //Add this reaction to the reaction lift of that message
                //MessageList.MessageReaction.push(p_oData.messagereaction[0])
                const updatedChat: any = [
                  ...MyChannelsMessagesDataByDateForThreadsRef.current,
                ];
                if (
                  updatedChat[i].Messages[j].MessageReactions === undefined ||
                  updatedChat[i].Messages[j].MessageReactions === null
                ) {
                  updatedChat[i].Messages[j].MessageReactions = [];
                }

                let object: any = {};
                object.Code = p_oData.messagereaction[0].code;
                object.ImageURL = p_oData.messagereaction[0].imageurl;
                object.PermType = p_oData.messagereaction[0].permtype;
                object.MGInteractionID =
                  p_oData.messagereaction[0].mginteractionid;
                object.SiteUserID = p_oData.messagereaction[0].siteuserid;
                object.Title = p_oData.messagereaction[0].title;
                object.UserName = p_oData.messagereaction[0].username;
                object.UserNameInitials =
                  p_oData.messagereaction[0].usernameinitials;
                object.MGMessageInteractionID =
                  p_oData.messagereaction[0].mgmessageinteractionid;
                object.MGMessageID = p_oData.mgmessageid;

                let NeedToPush = true;

                for (
                  let k = 0;
                  k <
                  MyChannelsMessagesDataByDateForThreadsRef.current[i].Messages[
                    j
                  ].MessageReactions.length;
                  k++
                ) {
                  if (
                    MyChannelsMessagesDataByDateForThreadsRef.current[i]
                      .Messages[j].MessageReactions[k]
                      .MGMessageInteractionID ===
                    p_oData.messagereaction[0].mgmessageinteractionid
                  ) {
                    NeedToPush = false;
                    break;
                  }
                }

                if (NeedToPush) {
                  updatedChat[i].Messages[j].MessageReactions.push(object);
                  SetMyChannelsMessagesByDateForThreads(updatedChat);
                }
              }
            }
          }
        }
      }
    }
  }

  function onEnterPress(e: any) {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      SaveMessage();
    }
  }

  function DeleteMyMessage(p_intMessageId: any) {
    //First Show the confirmation message
    SetMessageIdToBeDeleted(p_intMessageId);
    SetShowConfirmationModal(true);
  }

  function DeleteMessage(p_intMessageId: any) {
    let object: any = {};
    object.mgmessageid = p_intMessageId;
    object.channelid = chennelid;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGMessage/DeleteMessage", object)
      .then((response) => {
        if (response.data.Status === 1) {
          //GetMyChannelsMessages(MessageChannelID);
        }
        SetShowConfirmationModal(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function EditMyMessage(p_intMessageId: any, p_strMessageContent: any) {
    SetEditedMessage((prevState: any) => ({
      ...prevState,
      MessageID: p_intMessageId,
      Content: p_strMessageContent,
      ChannelID: chennelid,
    }));
    EditedMessage.MessageID = p_intMessageId;
    EditedMessage.Content = p_strMessageContent;
    EditedMessage.ChannelID = chennelid;

    HandleEditMessage();
  }

  function HandleEditMessage() {
    if (EditedMessage.MessageID > 0 && EditedMessage.ChannelID === chennelid) {
      //Hide attachment button and also add X button to cancel editing...
      SetUserMessage(EditedMessage.Content);
    }
  }

  function CancelEditMyMessage() {
    SetEditedMessage((prevState: any) => ({
      ...prevState,
      MessageID: 0,
      Content: "",
      ChannelID: "",
    }));

    SetUserMessage("");
  }

  function CloseThread() {
    SetThreadMessageObject({
      MGMessageID: 0,
    });

    SetMyChannelsMessagesForThreads([]);
    SetMyChannelsMessagesAttachmentsForThreads([]);
    SetMyChannelsMessagesReactionsForThreads([]);
  }

  function ReplyInThread(p_intMessageId: any) {
    for (let i = 0; i < MyChannelsMessagesData.length; i++) {
      if (p_intMessageId === MyChannelsMessagesData[i].MGMessageID) {
        SetThreadMessageObject(MyChannelsMessagesData[i]);
        //now get the messages for this thread....
        GetMyChannelsMessagesForThreads(MyChannelsMessagesData[i]);
      }
    }
  }

  function GetMyChannelsMessagesForThreads(p_objMyChannelsMessagesData: any) {
    let object: any = {};
    object.channelid = chennelid;
    object.originalmessageid = p_objMyChannelsMessagesData.MGMessageID;
    axiosAuth
      .post(
        "https://commapi.manyge.com/api/MGMessage/GetMyChannelsMessages",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var l_MyChannelsMessages = response.data.Data[0];
          var l_MyChannelsAttachments = response.data.Data[1];
          var l_MessageReactions = response.data.Data[2];
          let AttachmentArray = [];
          let ReactionArray = [];

          for (let i = 0; i < l_MyChannelsMessages.length; i++) {
            AttachmentArray = [];
            ReactionArray = [];

            for (let j = 0; j < l_MyChannelsAttachments.length; j++) {
              if (
                l_MyChannelsMessages[i].MGMessageID ===
                l_MyChannelsAttachments[j].MGMessageID
              ) {
                let Attachmentobject: any = {};
                Attachmentobject.FileURL =
                  AppContext.GetCommFiles +
                  workspaceid +
                  "/" +
                  chennelid +
                  "/" +
                  l_MyChannelsAttachments[j].Identifier +
                  "/" +
                  l_MyChannelsAttachments[j].Name;
                Attachmentobject.FileIconToDisplay =
                  AppContext.GetFileIconToDisplay(
                    AppContext.GetCommFiles +
                      workspaceid +
                      "/" +
                      chennelid +
                      "/" +
                      l_MyChannelsAttachments[j].Identifier +
                      "/" +
                      l_MyChannelsAttachments[j].Name
                  ); //AppContext.GetFileIconToDisplay(l_MyChannelsAttachments[j].FileURLS3);
                Attachmentobject.Name = l_MyChannelsAttachments[j].Name;
                AttachmentArray.push(Attachmentobject);
              }
            }

            for (let j = 0; j < l_MessageReactions.length; j++) {
              if (
                l_MyChannelsMessages[i].MGMessageID ===
                l_MessageReactions[j].MGMessageID
              ) {
                ReactionArray.push(l_MessageReactions[j]);
              }
            }

            l_MyChannelsMessages[i].MessageAttachment = AttachmentArray;
            l_MyChannelsMessages[i].MessageReactions = ReactionArray;
          }

          SetMyChannelsMessagesForThreads(l_MyChannelsMessages);
          SetMyChannelsMessagesAttachmentsForThreads(l_MyChannelsAttachments);
          SetMyChannelsMessagesReactionsForThreads(l_MessageReactions);

          let l_ScrollToBottom = ScrollToBottomRefForThreads.current;
          SetScrollToBottomForThreads(!l_ScrollToBottom);
          MadeGroupByDateForThreads(l_MyChannelsMessages);
          SetUserMessageForThreads("");
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function ChangeHandlerForThreads(e: any) {
    SetUserMessageForThreads(e.target.value);
  }

  function onEnterPressForThreads(e: any) {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      SaveMessageForThreads();
    }
  }
  const onEmojiClickForThreads = (event: any, emojiObject: any) => {
    SetUserMessageForThreads(`${UserMessageForThreads}${emojiObject.emoji}`);
  };

  function SaveMessageForThreads() {
    if (
      EditedMessageForThreads.MessageID > 0 &&
      EditedMessageForThreads.ChannelID === chennelid
    ) {
      UpdateMessageForThreads();
    } else {
      if (UploadFileList && UploadFileList.length > 0) {
        SaveFilesForThreads();
      } else {
        let object: any = [];
        SaveMyMessageForThreads(object);
      }
    }
  }
  function UpdateMessageForThreads() {
    if (UserMessageForThreads && UserMessageForThreads != "") {
      let object: any = {};
      object.mgmessageid = EditedMessageForThreads.MessageID;
      object.channelid = EditedMessageForThreads.ChannelID;
      object.content = UserMessageForThreads;
      SetUserMessageForThreads("");
      axiosAuth
        .post(AppContext.AppBaseURL + "api/MGMessage/UpdateMessage", object)
        .then((response) => {
          if (response.data.Status === 1) {
            CancelEditMyMessageForThreads();
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
        })

        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      AppContext.ShowNotification("error", "Please Enter Message");
    }
  }

  function SaveMyMessageForThreads(filesdata: any) {
    if (UserMessageForThreads && UserMessageForThreads != "") {
      let l_strmyidentifier = AppContext.GenerateUniqueGUID();
      Setmyidentifier(l_strmyidentifier);
      let object: any = {};
      object.myidentifier = l_strmyidentifier;
      object.channelid = chennelid;
      object.content = UserMessageForThreads;
      object.originalmessageid = ThreadMessageObject.MGMessageID;
      object.filesdata = filesdata;
      SetUserMessageForThreads("");
      axiosAuth
        .post(AppContext.AppBaseURL + "api/MGMessage/AddMessage", object)
        .then((response) => {
          if (response.data.Status === 1) {
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
        })

        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      AppContext.ShowNotification("error", "Please Enter Message");
    }
  }

  function EditMyMessageForThreads(
    p_intMessageId: any,
    p_strMessageContent: any
  ) {
    SetEditedMessageForThreads((prevState: any) => ({
      ...prevState,
      MessageID: p_intMessageId,
      Content: p_strMessageContent,
      ChannelID: chennelid,
    }));
    EditedMessageForThreads.MessageID = p_intMessageId;
    EditedMessageForThreads.Content = p_strMessageContent;
    EditedMessageForThreads.ChannelID = chennelid;

    HandleEditMessageForThreads();
  }

  function HandleEditMessageForThreads() {
    if (
      EditedMessageForThreads.MessageID > 0 &&
      EditedMessageForThreads.ChannelID === chennelid
    ) {
      //Hide attachment button and also add X button to cancel editing...
      SetUserMessageForThreads(EditedMessageForThreads.Content);
    }
  }

  function CancelEditMyMessageForThreads() {
    SetEditedMessageForThreads((prevState: any) => ({
      ...prevState,
      MessageID: 0,
      Content: "",
      ChannelID: "",
    }));

    SetUserMessageForThreads("");
  }

  const handleScrollForThreads = (e: any) => {
    if (e.target.scrollTop === 0) {
      console.log("top threads");
      e.target.scrollTop = 1; // 55;
      GetMyChannelsMessagesHistoricalForThreads();
    }
  };

  function GetMyChannelsMessagesHistoricalForThreads() {
    let object: any = {};
    //object.mgmessagechannelid = MessageChannelID;
    object.channelid = chennelid;
    object.originalmessageid = ThreadMessageObject.MGMessageID;
    if (
      MyChannelsMessagesDataForThreads &&
      MyChannelsMessagesDataForThreads[0] &&
      MyChannelsMessagesDataForThreads[0].MGMessageID
    ) {
      object.lastmessageid = MyChannelsMessagesDataForThreads[0].MGMessageID;
    } else {
      object.lastmessageid = 0;
    }
    axiosAuth
      .post(
        "https://commapi.manyge.com/api/MGMessage/GetMyChannelsMessagesHistorical",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var l_MyChannelsMessages = response.data.Data[0];
          var l_MyChannelsAttachments = response.data.Data[1];
          var l_MessageReactions = response.data.Data[2];
          SetMyChannelsMessagesAttachmentsForThreads(l_MyChannelsAttachments);
          SetMyChannelsMessagesReactionsForThreads(l_MessageReactions);
          let AttachmentArray = [];
          let ReactionArray = [];

          for (let i = 0; i < l_MyChannelsMessages.length; i++) {
            AttachmentArray = [];
            ReactionArray = [];

            for (let j = 0; j < l_MyChannelsAttachments.length; j++) {
              if (
                l_MyChannelsMessages[i].MGMessageID ===
                l_MyChannelsAttachments[j].MGMessageID
              ) {
                let Attachmentobject: any = {};
                Attachmentobject.FileURL =
                  AppContext.GetCommFiles +
                  workspaceid +
                  "/" +
                  chennelid +
                  "/" +
                  l_MyChannelsAttachments[j].Identifier +
                  "/" +
                  l_MyChannelsAttachments[j].Name;
                Attachmentobject.FileIconToDisplay =
                  AppContext.GetFileIconToDisplay(
                    AppContext.GetCommFiles +
                      workspaceid +
                      "/" +
                      chennelid +
                      "/" +
                      l_MyChannelsAttachments[j].Identifier +
                      "/" +
                      l_MyChannelsAttachments[j].Name
                  ); //AppContext.GetFileIconToDisplay(l_MyChannelsAttachments[j].FileURLS3);
                Attachmentobject.Name = l_MyChannelsAttachments[j].Name;
                AttachmentArray.push(Attachmentobject);
              }
            }

            for (let j = 0; j < l_MessageReactions.length; j++) {
              if (
                l_MyChannelsMessages[i].MGMessageID ===
                l_MessageReactions[j].MGMessageID
              ) {
                ReactionArray.push(l_MessageReactions[j]);
              }
            }

            l_MyChannelsMessages[i].MessageAttachment = AttachmentArray;
            l_MyChannelsMessages[i].MessageReactions = ReactionArray;
          }

          SetMyChannelsMessagesForThreads([
            ...l_MyChannelsMessages,
            ...MyChannelsMessagesDataForThreads,
          ]);

          const merged = [
            ...l_MyChannelsMessages,
            ...MyChannelsMessagesDataForThreads,
          ];

          MadeGroupByDateForThreads(merged);
          AppContext.SetMySelectedChannel(chennelid);
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function MadeGroupByDateForThreads(data: any) {
    // this gives an object with dates as keys
    const groups = data.reduce((groups: any, item: any) => {
      const date = item.MessageTime.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays: any = Object.keys(groups).map((date) => {
      return {
        date,
        Messages: groups[date],
      };
    });
    SetMyChannelsMessagesByDateForThreads(groupArrays);

    //console.log(groupArrays);
  }

  function MadeGroupByDate(data: any) {
    // this gives an object with dates as keys
    const groups = data.reduce((groups: any, item: any) => {
      const date = item.MessageTime.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        Messages: groups[date],
      };
    });
    SetMyChannelsMessagesByDate(groupArrays);

    //console.log(groupArrays);
  }

  const handleScroll = (e: any) => {
    if (e.target.scrollTop === 0) {
      console.log("top");
      e.target.scrollTop = 1; // 55;
      GetMyChannelsMessagesHistorical();
    }
  };

  function GetMyChannelsMessagesHistorical() {
    let object: any = {};
    //object.mgmessagechannelid = MessageChannelID;
    object.channelid = chennelid;
    object.originalmessageid = 0;
    if (
      MyChannelsMessagesData &&
      MyChannelsMessagesData[0] &&
      MyChannelsMessagesData[0].MGMessageID
    ) {
      object.lastmessageid = MyChannelsMessagesData[0].MGMessageID;
    } else {
      object.lastmessageid = 0;
    }
    axiosAuth
      .post(
        "https://commapi.manyge.com/api/MGMessage/GetMyChannelsMessagesHistorical",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var l_MyChannelsMessages = response.data.Data[0];
          var l_MyChannelsAttachments = response.data.Data[1];
          var l_MessageReactions = response.data.Data[2];
          SetMyChannelsMessagesAttachments(l_MyChannelsAttachments);
          SetMyChannelsMessagesReactions(l_MessageReactions);
          let AttachmentArray = [];
          let ReactionArray = [];

          for (let i = 0; i < l_MyChannelsMessages.length; i++) {
            AttachmentArray = [];
            ReactionArray = [];

            for (let j = 0; j < l_MyChannelsAttachments.length; j++) {
              if (
                l_MyChannelsMessages[i].MGMessageID ===
                l_MyChannelsAttachments[j].MGMessageID
              ) {
                let Attachmentobject: any = {};
                Attachmentobject.FileURL =
                  AppContext.GetCommFiles +
                  workspaceid +
                  "/" +
                  chennelid +
                  "/" +
                  l_MyChannelsAttachments[j].Identifier +
                  "/" +
                  l_MyChannelsAttachments[j].Name;
                Attachmentobject.FileIconToDisplay =
                  AppContext.GetFileIconToDisplay(
                    AppContext.GetCommFiles +
                      workspaceid +
                      "/" +
                      chennelid +
                      "/" +
                      l_MyChannelsAttachments[j].Identifier +
                      "/" +
                      l_MyChannelsAttachments[j].Name
                  ); //AppContext.GetFileIconToDisplay(l_MyChannelsAttachments[j].FileURLS3);
                Attachmentobject.Name = l_MyChannelsAttachments[j].Name;
                AttachmentArray.push(Attachmentobject);
              }
            }

            for (let j = 0; j < l_MessageReactions.length; j++) {
              if (
                l_MyChannelsMessages[i].MGMessageID ===
                l_MessageReactions[j].MGMessageID
              ) {
                ReactionArray.push(l_MessageReactions[j]);
              }
            }

            l_MyChannelsMessages[i].MessageAttachment = AttachmentArray;
            l_MyChannelsMessages[i].MessageReactions = ReactionArray;
          }

          SetMyChannelsMessages([
            ...l_MyChannelsMessages,
            ...MyChannelsMessagesData,
          ]);

          const merged = [...l_MyChannelsMessages, ...MyChannelsMessagesData];

          MadeGroupByDate(merged);
          AppContext.SetMySelectedChannel(chennelid);
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function UpdateMessageReactionThis(
    p_intReactionId: any,
    p_intMessageId: any,
    PermType: any
  ) {
    if (PermType === 1) {
      UpdateMessageReaction(p_intReactionId, p_intMessageId);
    }
  }

  function UpdateMessageReaction(p_intReactionId: any, p_intMessageId: any) {
    let l_strmyidentifierreaction = AppContext.GenerateUniqueGUID();
    Setmyidentifierreaction(l_strmyidentifierreaction);
    let object: any = {};
    object.myidentifierreaction = l_strmyidentifierreaction;
    object.mginteractionid = p_intReactionId;
    object.mgmessageid = p_intMessageId;
    object.channelid = chennelid;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/MGMessage/UpdateInteractionMessage",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          //GetMyChannelsMessages(MessageChannelID);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  ///**************************Create Channel & Category Starts*********************************** */////

  function CreateChannel() {
    if (
      ChannelObject.Name !== "" &&
      ChannelObject.CategoryId !== "" &&
      ChannelObject.CategoryId > 0
    ) {
      let object: any = {};
      object.typeid = 1;
      object.mgworkspaceid = props.workspaceid;
      object.mgmessagechannelid = 0;
      object.mgmessagecategoryid = ChannelObject.CategoryId;
      object.permissiontype = 1;
      object.title = ChannelObject.Name;
      object.description = ChannelObject.Description;
      object.originalchannelid = 0;
      axiosAuth
        .post(AppContext.AppBaseURL + "api/MGMessage/UpdateChannelInfo", object)
        .then((response) => {
          if (response.data.Status === 1) {
            var l_MessageCategory = response.data.Data[0];
            var l_MessageChannel = response.data.Data[1];
            var l_SelectedMessageCategory = ShowSelectedChannel(
              l_MessageCategory,
              l_MessageChannel
            );
            SetMessageCategory(l_SelectedMessageCategory);
            SetMessageChannel(l_MessageChannel);
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
          SetShowChannelModal(false);
          SetChannelObject({
            CategoryId: 0,
            Name: "",
            Description: "",
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      if (ChannelObject.Name === "" && ChannelObject.CategoryId === 0) {
        AppContext.ShowNotification(
          "error",
          "Please Select Category And Enter Name."
        );
      } else if (ChannelObject.Name === "") {
        AppContext.ShowNotification("error", "Please Enter Name.");
      } else if (
        ChannelObject.CategoryId === 0 ||
        ChannelObject.CategoryId === ""
      ) {
        AppContext.ShowNotification("error", "Please Select Category.");
      }
    }
  }

  function CreateCategory() {
    if (CategoryObject.Name !== "") {
      let object: any = {};
      object.typeid = 1;
      object.mgmessagecategoryid = 0;
      object.mgworkspaceid = props.workspaceid;
      object.title = CategoryObject.Name;
      object.description = CategoryObject.Description;
      axiosAuth
        .post(
          AppContext.AppBaseURL + "api/MGMessage/UpdateChannelCategoryInfo",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            var l_MessageCategory = response.data.Data[0];
            var l_MessageChannel = response.data.Data[1];
            var l_SelectedMessageCategory = ShowSelectedChannel(
              l_MessageCategory,
              l_MessageChannel
            );
            SetMessageCategory(l_SelectedMessageCategory);
            SetMessageChannel(l_MessageChannel);
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
          SetShowCategoryModal(false);
          SetCategoryObject({
            Name: "",
            Description: "",
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      AppContext.ShowNotification("error", "Please Enter Name.");
    }
  }

  ///**************************Create Channel & Category Ends*********************************** */////

  ///**************************DragDrop Starts*********************************** */////
  const [DragDropFeatures, SetDragDropFeatures] = useState({
    ObjectID: 1,
    ObjectType: 6,
    MaxAllowedFiles: 3,
    UploadType: 1,
    FolderName: "Defualt",
    OtherParams: "",
    PTUploadedFiles: [],
  });

  // SetDragDropFeatures((prevState) => ({
  //   ...prevState,
  //   ObjectID: l_responseData[0][0].CVProfileID,
  // }));

  const [UploadFileList, SetUploadFileList] = useState([]);
  const [TriggerChild, SetTriggerChild] = useState(false);

  function HandleDragDropCallback(ChildData: any) {
    SetUploadFileList(ChildData);
  }

  function SaveFiles() {
    if (UploadFileList && UploadFileList.length > 0) {
      let object: any = {};
      object.typeid = 1;
      object.attachmenttype = DragDropFeatures.ObjectType;
      object.objectid = DragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = UploadFileList;
      SetLoadingState(true);
      axiosAuth
        .post(AppContext.FilesAPI + "api/PTAttachment/SaveFilesData", object)
        .then((response) => {
          if (response.data.Status === 1) {
            SetTriggerChild(!TriggerChild);
            let l_response = response.data.Data;
            SaveMyMessage(l_response);
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
          SetUploadFileList([]);
          SetShowDragDrop(false);
          SetShowAttachemtButton(false);
          SetLoadingState(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }

  function SaveFilesForThreads() {
    if (UploadFileList && UploadFileList.length > 0) {
      let object: any = {};
      object.typeid = 1;
      object.attachmenttype = DragDropFeatures.ObjectType;
      object.objectid = DragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = UploadFileList;
      SetLoadingState(true);
      axiosAuth
        .post(AppContext.FilesAPI + "api/PTAttachment/SaveFilesData", object)
        .then((response) => {
          if (response.data.Status === 1) {
            SetTriggerChild(!TriggerChild);
            let l_response = response.data.Data;
            SaveMyMessageForThreads(l_response);
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
          SetUploadFileList([]);
          SetShowDragDropForThreads(false);
          SetShowAttachemtButtonForThreads(false);
          SetLoadingState(false);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }

  function ShowHideUploadButtonForThreads() {
    SetShowAttachemtButtonForThreads(!ShowAttachemtButtonForThreads);
    SetShowDragDropForThreads(false);
  }

  function ShowHideDragdropForThreads() {
    if (ShowDragDropForThreads === false) {
      SetShowDragDropForThreads(true);
      SetShowAttachemtButtonForThreads(false);
    }
  }

  function ShowHideUploadButton() {
    SetShowAttachemtButton(!ShowAttachemtButton);
    SetShowDragDrop(false);
  }

  function ShowHideDragdrop() {
    if (ShowDragDrop === false) {
      SetShowDragDrop(true);
      SetShowAttachemtButton(false);
    }
  }

  function ResetEditMessage() {
    SetShoweditdrop(false);
  }

  ///**************************DragDrop Ends*********************************** */////

  function SetChatTextAreaHeight(e: any) {
    const TextArea: any = document.getElementById("ChatTextArea");
    TextArea.style.height = "auto";
    TextArea.style.height = TextArea.scrollHeight + "px";
  }

  return (
    <>
      {LoadingState && <LoadingAnimation />}

      <section className="msg--body" onClick={(e) => CheckClickEvent(e)}>
        <div
          className={`msg--body__lt ${
            ThreadMessageObject.MGMessageID !== 0 ? "msg--body__lt__sm" : ""
          }`}
        >
          {/* msg--body__lt__sm */}
          {/* <div className="msg--body__lt__head">
            <a className="msg--body__user">
              <span className="msg--body__name">{SelectedChennelName}</span>
            </a>
          </div> */}
          <div className="msg--body__messages">
            <div className="msg--body__messages__chats">
              <div
                className="msg--body__messages__chats__scroll custom--scroll"
                id="ChatBox"
                style={{ flex: 1, overflowY: "auto" }}
                onScroll={handleScroll}
              >
                {MyChannelsMessagesDataByDate &&
                  MyChannelsMessagesDataByDate.map((item: any) => (
                    <>
                      <div className="msg--body__date_sepr">
                        <span>
                          {" "}
                          {moment(
                            AppContext.ConvertDateTimeToLocal(item.date)
                          ).format("MMMM DD, YYYY")}
                        </span>
                        <hr />
                      </div>

                      {item.Messages &&
                        item.Messages.map((Message: any) => (
                          <div
                            className="msg--body__post"
                            onMouseLeave={() => ResetEditMessage()}
                            key={Message.MGMessageID}
                          >
                            <div className="msg--body__post__chat">
                              <div className="msg--body__emoji">
                                {MessageReactions &&
                                  MessageReactions.map((Reactions: any) => (
                                    <a
                                      title={Reactions.Title}
                                      onClick={() =>
                                        UpdateMessageReaction(
                                          Reactions.MGInteractionID,
                                          Message.MGMessageID
                                        )
                                      }
                                    >
                                      {/* <img
                                        src={`/images/reactions/${Reactions.Code}.png`}
                                          alt="icon"
                                          width="22px"
                                          height="22px"
                                        /> */}
                                      <span>{Reactions.Code}</span>
                                    </a>
                                  ))}

                                <a
                                  onClick={() =>
                                    ReplyInThread(Message.MGMessageID)
                                  }
                                  title="Reply in Threads"
                                >
                                  <img
                                    src="/images/thread.svg"
                                    alt="icon"
                                    width="18px"
                                    height="18px"
                                  />
                                </a>

                                {Message.PermType === 1 && (
                                  <div className="edit--del">
                                    <a onClick={ShowHideeditdrop}>
                                      <img
                                        src="/images/dots.svg"
                                        alt="icon"
                                        width="18px"
                                        height="18px"
                                      />
                                    </a>
                                    {Showeditdrop ? (
                                      <div className="edit--del--drop">
                                        <a
                                          onClick={() =>
                                            EditMyMessage(
                                              Message.MGMessageID,
                                              Message.Content
                                            )
                                          }
                                        >
                                          <img
                                            src="/images/edit.png"
                                            alt="icon"
                                            width="22px"
                                            height="22px"
                                          />
                                          <span>Edit Message</span>
                                        </a>
                                        <a
                                          onClick={() =>
                                            DeleteMyMessage(Message.MGMessageID)
                                          }
                                        >
                                          <img
                                            src="/images/delete.png"
                                            alt="icon"
                                            width="22px"
                                            height="22px"
                                          />
                                          <span>Delete Message</span>
                                        </a>
                                      </div>
                                    ) : (
                                      <span></span>
                                    )}
                                  </div>
                                )}
                              </div>
                              <div className="post__chat__name__time">
                                <div className="post__chat__user">
                                  {/* <img
                        src="/images/user.png"
                        alt="user"
                        width="30px"
                        height="30px"
                      /> */}
                                  <span>{Message.UserNameInitials}</span>
                                </div>
                                <div className="post__chat__message">
                                  <span className="post__chat__name">
                                    {Message.UserName}
                                  </span>
                                  <span className="post__chat__time">
                                    {moment(
                                      AppContext.ConvertDateTimeToLocal(
                                        Message.MessageTime
                                      )
                                    ).format("h:mm a")}
                                  </span>
                                  {Message.IsEdited && (
                                    <img
                                      src="/images/edit.png"
                                      alt="icon"
                                      width="22px"
                                      height="22px"
                                      title="This message has been edited"
                                      className="edited--message"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="post__chat__main__message">
                                <span className="post__chat__message__time"></span>

                                <span className="post__chat__message__text">
                                  {Message.Content}
                                </span>
                              </div>
                              <div className="message-attachment">
                                {Message.MessageAttachment &&
                                  Message.MessageAttachment.map(
                                    (Attachment: any) => (
                                      <a
                                        href={Attachment.FileURL}
                                        target="_blank"
                                        download
                                      >
                                        <img
                                          src={Attachment.FileIconToDisplay}
                                          width="16px"
                                          height="16px"
                                          alt="Attachment"
                                        />
                                      </a>
                                    )
                                  )}
                              </div>

                              <div className="msg--body__react__tags">
                                <div className="msg--body__reacts">
                                  {Message.MessageReactions &&
                                    Message.MessageReactions.map(
                                      (Reaction: any) => (
                                        <a
                                          title={Reaction.UserName + " reacted"}
                                          onClick={() =>
                                            UpdateMessageReactionThis(
                                              Reaction.MGInteractionID,
                                              Reaction.MGMessageID,
                                              Reaction.PermType
                                            )
                                          }
                                        >
                                          <span>{Reaction.Code}</span>
                                          {/* <span>1</span> */}
                                        </a>
                                      )
                                    )}
                                </div>
                                {/* <div className="msg--body__reacts msg--body__tags">
                                    <a>#Channel</a>
                                    <a>#new_private_channel</a>
                                  </div> */}
                              </div>
                              {Message.ReplyCount > 0 && (
                                <div className="post--chat__thread__main">
                                  <a
                                    className="post--chat__thread"
                                    onClick={() =>
                                      ReplyInThread(Message.MGMessageID)
                                    }
                                  >
                                    <span className="post--chat__thread__message">
                                      {Message.LastReplyContents}
                                      {/* need to split */}
                                    </span>
                                    <span className="post--chat__thread__count">
                                      <span>
                                        {Message.ReplyCount}{" "}
                                        {Message.ReplyCount === 1
                                          ? "Message"
                                          : "Messages"}{" "}
                                      </span>
                                      <img
                                        src="/images/icon-thread-arrow.png"
                                        alt="arrow"
                                        width="8px"
                                        height="4px"
                                      />
                                    </span>
                                    <span className="post--chat__thread__time">
                                      {AppContext.DateDifference(
                                        Message.LastReplyTime
                                      )}
                                    </span>
                                  </a>
                                </div>
                              )}
                            </div>
                            <div ref={bottomRef} />
                          </div>
                        ))}
                    </>
                  ))}
              </div>

              <div className="msg--body__type">
                {EditedMessage.MessageID > 0 &&
                EditedMessage.ChannelID === chennelid ? (
                  <a
                    className="msg--body__attachment"
                    onClick={() => CancelEditMyMessage()}
                  >
                    <img
                      src="/images/icon-cross.png"
                      width="20px"
                      height="20px"
                      alt="attachment"
                    />
                  </a>
                ) : (
                  <a
                    className="msg--body__attachment"
                    onClick={() => ShowHideUploadButton()}
                  >
                    <img
                      src="/images/attach.png"
                      width="20px"
                      height="20px"
                      alt="attachment"
                    />
                  </a>
                )}
                {ShowAttachemtButton && (
                  <div
                    className="msg--body__actions__link"
                    onClick={() => ShowHideDragdrop()}
                  >
                    <a>
                      <img
                        src="/images/upload.svg"
                        width="16px"
                        height="16px"
                        alt="upload"
                      />
                      <span>Upload Attachment</span>
                    </a>
                  </div>
                )}

                {ShowDragDrop && (
                  <div className="msg--body__uploader">
                    <div className="msg--body__dragdrop">
                      <DragDrop
                        ParentFeatures={DragDropFeatures}
                        DragDropCallback={HandleDragDropCallback}
                        TriggerChild={TriggerChild}
                      />
                    </div>
                  </div>
                )}
                <textarea
                  id="ChatTextArea"
                  placeholder="Type Message"
                  name="UserMessage"
                  value={UserMessage}
                  onKeyUp={(e) => SetChatTextAreaHeight(e)}
                  onKeyDown={onEnterPress}
                  onChange={ChangeHandler}
                ></textarea>
                <div>
                  <a
                    onClick={ShowHidePicker}
                    className="msg--body__smiley__anchor"
                  >
                    <img
                      src="/images/smiley.png"
                      alt="smiley"
                      width="25px"
                      height="25px"
                    />
                  </a>
                  <br />
                  {ShowEmoji ? (
                    <div className="msg--body__picker">
                      <Picker onEmojiClick={onEmojiClick} />
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
              <div className="msg--body__send">
                <a
                  onClick={() => SaveMessage()}
                  className="msg--body__send__anchor"
                >
                  <img
                    src="/images/send-white.png"
                    alt="send"
                    width="22px"
                    height="22px"
                    className="msg--body__send__btnw"
                  />
                  <img
                    src="/images/send-blue.png"
                    alt="send"
                    width="22px"
                    height="22px"
                    className="msg--body__send__btnb"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        {ThreadMessageObject.MGMessageID > 0 && (
          <div className="msg--body__lt msg--body__rt">
            <div className="msg--body__lt__head">
              {/* <a className="msg--body__user">
              <span className="msg--body__user__wrap">
                <img
                  src="/images/user.png"
                  alt="User"
                  width="22px"
                  height="22px"
                  className="msg--body__user__img"
                />
                <span className="msg--body__initials">AP</span>
              </span>
              <span className="msg--body__name">Atif Paracha</span>
            </a> */}
              <div className="msg--body__thread">
                <div className="msg--body__thread__title">
                  {ThreadMessageObject.Content}
                </div>
                <div className="msg--body__thread__icons">
                  {/* <a>
                    <img
                      src="/images/icon-dots.png"
                      alt="dots"
                      width="20px"
                      height="20px"
                    />
                  </a> */}
                  <a onClick={() => CloseThread()}>
                    <img
                      src="/images/icon-cross.png"
                      alt="dots"
                      width="20px"
                      height="20px"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="msg--body__messages">
              <div className="msg--body__messages__chats">
                <div
                  className="msg--body__messages__chats__scroll custom--scroll"
                  id="ChatBoxForThreads"
                  style={{ flex: 1, overflowY: "auto" }}
                  onScroll={handleScrollForThreads}
                >
                  <div className="msg--body__date_sepr"></div>
                  {MyChannelsMessagesDataByDateForThreads &&
                    MyChannelsMessagesDataByDateForThreads.map((item: any) => (
                      <>
                        <div className="msg--body__date_sepr">
                          <span>
                            {" "}
                            {moment(
                              AppContext.ConvertDateTimeToLocal(item.date)
                            ).format("MMMM DD, YYYY")}
                          </span>
                          <hr />
                        </div>

                        {item.Messages &&
                          item.Messages.map((Message: any) => (
                            <div
                              className="msg--body__post"
                              key={Message.MGMessageID}
                              onMouseLeave={() => ResetEditMessage()}
                            >
                              <div className="msg--body__post__chat">
                                <div className="msg--body__emoji">
                                  {MessageReactions &&
                                    MessageReactions.map((Reactions: any) => (
                                      <a
                                        title={Reactions.Title}
                                        onClick={() =>
                                          UpdateMessageReaction(
                                            Reactions.MGInteractionID,
                                            Message.MGMessageID
                                          )
                                        }
                                      >
                                        <span>{Reactions.Code}</span>
                                      </a>
                                    ))}

                                  {Message.PermType === 1 && (
                                    <div className="edit--del">
                                      <a onClick={ShowHideeditdrop}>
                                        <img
                                          src="/images/dots.svg"
                                          alt="icon"
                                          width="18px"
                                          height="18px"
                                        />
                                      </a>
                                      {Showeditdrop ? (
                                        <div className="edit--del--drop">
                                          <a
                                            onClick={() =>
                                              EditMyMessageForThreads(
                                                Message.MGMessageID,
                                                Message.Content
                                              )
                                            }
                                          >
                                            <img
                                              src="/images/edit.png"
                                              alt="icon"
                                              width="22px"
                                              height="22px"
                                            />
                                            <span>Edit Message</span>
                                          </a>
                                          <a
                                            onClick={() =>
                                              DeleteMyMessage(
                                                Message.MGMessageID
                                              )
                                            }
                                          >
                                            <img
                                              src="/images/delete.png"
                                              alt="icon"
                                              width="22px"
                                              height="22px"
                                            />
                                            <span>Delete Message</span>
                                          </a>
                                        </div>
                                      ) : (
                                        <span></span>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="post__chat__name__time">
                                  <div className="post__chat__user">
                                    <span>{Message.UserNameInitials}</span>
                                  </div>
                                  <div className="post__chat__message">
                                    <span className="post__chat__name">
                                      {Message.UserName}
                                    </span>
                                    <span className="post__chat__time">
                                      {moment(
                                        AppContext.ConvertDateTimeToLocal(
                                          Message.MessageTime
                                        )
                                      ).format("h:mm a")}
                                    </span>
                                    {Message.IsEdited && (
                                      <img
                                        src="/images/edit.png"
                                        alt="icon"
                                        width="22px"
                                        height="22px"
                                        title="This message has been edited"
                                        className="edited--message"
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="post__chat__main__message">
                                  <span className="post__chat__message__time"></span>

                                  <span className="post__chat__message__text">
                                    {Message.Content}
                                  </span>
                                </div>
                                <div className="message-attachment">
                                  {Message.MessageAttachment &&
                                    Message.MessageAttachment.map(
                                      (Attachment: any) => (
                                        <a
                                          href={Attachment.FileURL}
                                          target="_blank"
                                          download
                                        >
                                          <img
                                            src={Attachment.FileIconToDisplay}
                                            width="16px"
                                            height="16px"
                                            alt="Attachment"
                                          />
                                        </a>
                                      )
                                    )}
                                </div>

                                <div className="msg--body__react__tags">
                                  <div className="msg--body__reacts">
                                    {Message.MessageReactions &&
                                      Message.MessageReactions.map(
                                        (Reaction: any) => (
                                          <a
                                            title={
                                              Reaction.UserName + " reacted"
                                            }
                                            onClick={() =>
                                              UpdateMessageReactionThis(
                                                Reaction.MGInteractionID,
                                                Reaction.MGMessageID,
                                                Reaction.PermType
                                              )
                                            }
                                          >
                                            <span>{Reaction.Code}</span>
                                          </a>
                                        )
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div ref={bottomRefForThreads} />
                            </div>
                          ))}
                      </>
                    ))}
                </div>

                <div className="msg--body__type">
                  {EditedMessageForThreads.MessageID > 0 &&
                  EditedMessageForThreads.ChannelID === chennelid ? (
                    <a
                      className="msg--body__attachment"
                      onClick={() => CancelEditMyMessageForThreads()}
                    >
                      <img
                        src="/images/icon-cross.png"
                        width="20px"
                        height="20px"
                        alt="attachment"
                      />
                    </a>
                  ) : (
                    <a
                      className="msg--body__attachment"
                      onClick={() => ShowHideUploadButtonForThreads()}
                    >
                      <img
                        src="/images/attach.png"
                        width="20px"
                        height="20px"
                        alt="attachment"
                      />
                    </a>
                  )}
                  {ShowAttachemtButtonForThreads && (
                    <div
                      className="msg--body__actions__link"
                      onClick={() => ShowHideDragdropForThreads()}
                    >
                      <a>
                        <img
                          src="/images/upload.svg"
                          width="16px"
                          height="16px"
                          alt="upload"
                        />
                        <span>Upload Attachment</span>
                      </a>
                    </div>
                  )}

                  {ShowDragDropForThreads && (
                    <div className="msg--body__uploader">
                      <div className="msg--body__dragdrop">
                        <DragDrop
                          ParentFeatures={DragDropFeatures}
                          DragDropCallback={HandleDragDropCallback}
                          TriggerChild={TriggerChild}
                        />
                      </div>
                    </div>
                  )}
                  <textarea
                    placeholder="Type Message"
                    name="UserMessageForThreads"
                    value={UserMessageForThreads}
                    onKeyDown={onEnterPressForThreads}
                    onChange={ChangeHandlerForThreads}
                  ></textarea>
                  <div>
                    <a
                      onClick={ShowHidePickerForThreads}
                      className="msg--body__smiley__anchor"
                    >
                      <img
                        src="/images/smiley.png"
                        alt="smiley"
                        width="25px"
                        height="25px"
                      />
                    </a>
                    <br />
                    {ShowEmojiForThreads ? (
                      <div className="msg--body__picker">
                        <Picker onEmojiClick={onEmojiClickForThreads} />
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </div>
                </div>
                <div className="msg--body__send">
                  <a
                    onClick={() => SaveMessageForThreads()}
                    className="msg--body__send__anchor"
                  >
                    <img
                      src="/images/send-white.png"
                      alt="send"
                      width="22px"
                      height="22px"
                      className="msg--body__send__btnw"
                    />
                    <img
                      src="/images/send-blue.png"
                      alt="send"
                      width="22px"
                      height="22px"
                      className="msg--body__send__btnb"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      <Modal show={ShowChannelModal} onHide={ShowHideChannelModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal--create min-h-100">
            <div className="modal--create__inputs mt--30">
              <span className="input--text">Category</span>

              <select
                className="input--type__text"
                onChange={(e) =>
                  SetChannelObject((prevState: any) => ({
                    ...prevState,
                    CategoryId: e.target.value,
                  }))
                }
              >
                <option value="">Please Select</option>
                {MessageCategoryData &&
                  MessageCategoryData.map((Item: any) => (
                    <option
                      key={Item.MGMessageCategoryID}
                      value={Item.MGMessageCategoryID}
                    >
                      {Item.Title}
                    </option>
                  ))}
              </select>
            </div>

            <div className="modal--create__inputs mt--30">
              <span className="input--text">Name</span>
              <input
                type="text"
                className="input--type__text"
                value={ChannelObject.Name}
                onChange={(e) =>
                  SetChannelObject((prevState: any) => ({
                    ...prevState,
                    Name: e.target.value,
                  }))
                }
              />
            </div>
            <div className="modal--create__inputs mt--35">
              <span className="input--text">Description</span>
              <textarea
                className="input--type__text"
                value={ChannelObject.Description}
                onChange={(e) =>
                  SetChannelObject((prevState: any) => ({
                    ...prevState,
                    Description: e.target.value,
                  }))
                }
              ></textarea>
            </div>
            {/* <hr className="create--hr" /> */}
            <div className="modal--create__inputs">
              <div className="modal--button mt--20">
                <a onClick={() => CreateChannel()}>Create</a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={ShowCategoryModal} onHide={ShowHideCategoryModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal--create min-h-100">
            <div className="modal--create__inputs mt--30">
              <span className="input--text">Name</span>
              <input
                type="text"
                className="input--type__text"
                value={CategoryObject.Name}
                onChange={(e) =>
                  SetCategoryObject((prevState: any) => ({
                    ...prevState,
                    Name: e.target.value,
                  }))
                }
              />
            </div>
            <div className="modal--create__inputs mt--35">
              <span className="input--text">Description</span>
              <textarea
                className="input--type__text"
                value={CategoryObject.Description}
                onChange={(e) =>
                  SetCategoryObject((prevState: any) => ({
                    ...prevState,
                    Description: e.target.value,
                  }))
                }
              ></textarea>
            </div>
            {/* <hr className="create--hr" /> */}
            <div className="modal--create__inputs">
              <div className="modal--button mt--20">
                <a onClick={() => CreateCategory()}>Create</a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={ShowConfirmationModal}>
        <Modal.Header
          closeButton
          onClick={() => SetShowConfirmationModal(false)}
        >
          <Modal.Title>Delete message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="del--confirm">
            Are you sure you want to delete this message? This cannot be undone.
          </div>
          <div className="modal--button del--modal__btn">
            <a onClick={() => SetShowConfirmationModal(false)}>Cancel</a>
            <a onClick={() => DeleteMessage(MessageIdToBeDeleted)}>Delete</a>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal--create">
            <span className="modal--create__about">
              Lorem Ipsum sit dolor amit Lorem Ipsum sit dolor amit Lorem Ipsum
              sit dolor amit Lorem Ipsum sit dolor amit Lorem Ipsum sit dolor
            </span>
            <div className="modal--create__inputs mt--30">
              <span className="input--text">Name</span>
              <input type="text" className="input--type__text" />
            </div>
            <div className="modal--create__inputs mt--35">
              <span className="input--text">Description</span>
              <textarea className="input--type__text"></textarea>
            </div>
            <hr className="create--hr" />
            <div className="modal--create__inputs mt--20">
              <div className="modal--create__inputs__radio">
                <div className="input--radio radio--btn">
                  <input type="radio" className="input--type__radio" />
                  <span className="radio--mark"></span>
                  <span className="input--radio__text">Public</span>
                </div>
                <div className="input--radio radio--btn">
                  <input type="radio" className="input--type__radio" />
                  <span className="radio--mark"></span>
                  <span className="input--radio__text">Private</span>
                </div>
              </div>
            </div>
            <div className="modal--create__inputs mt--15">
              <div className="modal--create__inputs__radio">
                <div className="input--radio radio--btn">
                  <input type="radio" className="input--type__radio" />
                  <span className="radio--mark"></span>
                  <span className="input--radio__text">
                    Add all 4 members of Nimble Geeks
                  </span>
                </div>
              </div>
            </div>
            <div className="modal--create__inputs">
              <div className="modal--create__inputs__radio">
                <div className="input--radio radio--btn">
                  <input type="radio" className="input--type__radio" />
                  <span className="radio--mark"></span>
                  <span className="input--radio__text">
                    Add specific people to Private-Channel
                  </span>
                </div>
              </div>
            </div>
            <div className="modal--create__inputs">
              <div className="modal--create__add">
                <div className="modal--create__add__member">
                  <div className="modal--create__add__member__flex">
                    <span className="modal--create__add__member__image">
                      <img
                        src="/images/user.png"
                        alt="user"
                        width="20px"
                        height="20px"
                      />
                    </span>
                    <span className="modal--create__add__member__name">
                      Atif Paracha
                    </span>
                    <a>
                      <img
                        src="/images/icon-cross-modal.png"
                        alt="user"
                        width="6px"
                        height="6px"
                        className="remove--user"
                      />
                    </a>
                  </div>
                </div>
                <div className="modal--create__add__member">
                  <div className="modal--create__add__member__flex">
                    <span className="modal--create__add__member__image">
                      UA
                    </span>
                    <span className="modal--create__add__member__name">
                      Umer Afzal
                    </span>
                    <a>
                      <img
                        src="/images/icon-cross-modal.png"
                        alt="user"
                        width="6px"
                        height="6px"
                        className="remove--user"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <hr className="create--hr" />
            <div className="modal--create__inputs mt--20">
              <div className="modal--create__inputs__radio">
                <div className="input--radio check--btn">
                  <input type="checkbox" className="input--type__check" />
                  <span className="check--mark"></span>
                  <span className="input--check__text">
                    Share channel outside company
                  </span>
                </div>
              </div>
            </div>
            <div className="modal--create__inputs">
              <div className="modal--button">
                <a>Create</a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src="/images/privatew.png"
              width="40px"
              height="40px"
              alt="channel-icon"
            />
            Private-Channel
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal--create">
            <Tabs
              defaultActiveKey="Settings"
              transition={false}
              id="noanim-tab-example"
              className="modal--create__about"
            >
              <Tab eventKey="Settings" title="Settings">
                <div className="modal--create__inputs mt--30">
                  <span className="input--text">Name</span>
                  <div className="edit--input">
                    <input
                      type="text"
                      className="input--type__text"
                      value="Atif Paracha"
                    />
                    <a>
                      <img
                        src="/images/edit.png"
                        width="15px"
                        height="15px"
                        alt="edit"
                      />
                    </a>
                  </div>
                </div>
                <div className="modal--create__inputs mt--35">
                  <span className="input--text">Description</span>
                  <div className="edit--input edit--input--start">
                    <textarea className="input--type__text">
                      Description of atif Paracha
                    </textarea>
                    <a>
                      <img
                        src="/images/edit.png"
                        width="15px"
                        height="15px"
                        alt="edit"
                      />
                    </a>
                  </div>
                </div>
                <div className="modal--create__inputs mt--20">
                  <div className="modal--create__inputs__radio">
                    <div className="input--radio radio--btn">
                      <input type="radio" className="input--type__radio" />
                      <span className="radio--mark"></span>
                      <span className="input--radio__text">Public</span>
                    </div>
                    <div className="input--radio radio--btn">
                      <input type="radio" className="input--type__radio" />
                      <span className="radio--mark"></span>
                      <span className="input--radio__text">Private</span>
                    </div>
                  </div>
                </div>
                <div className="channel--archive">
                  <a>
                    <img
                      src="/images/archive.png"
                      width="25px"
                      height="25px"
                      alt="edit"
                    />
                    <span>Archive this channel</span>
                  </a>
                </div>
                <div className="channel--archive">
                  <a>
                    <img
                      src="/images/delete.png"
                      width="25px"
                      height="25px"
                      alt="edit"
                    />
                    <span>Delete this channel</span>
                  </a>
                </div>
              </Tab>
              <Tab eventKey="Members" title="Members">
                <div className="add-remaining">
                  <span className="modal--create__about">
                    This channel had 4 out of the 10 people in the company.{" "}
                  </span>
                  <a>Add Remaining</a>
                </div>
                <div className="modal--create__inputs search--members mt--30">
                  <input
                    type="text"
                    className="input--type__text"
                    placeholder="Find Members"
                  />
                </div>
                <div className="online--users">
                  <div className="online--users__details">
                    <div className="online--users__data">
                      <div className="online--users__images">
                        <span className="online--users__initials">UA</span>
                        <img
                          src="/images/online.png"
                          width="7px"
                          height="7px"
                          alt="user"
                          className="online--users__status"
                        />
                      </div>
                      <span className="online--users__name">Umer Afzal</span>
                    </div>
                    <a className="online--users__remove">
                      <img
                        src="/images/icon-cross-modal.png"
                        width="10px"
                        height="10px"
                        alt="user"
                      />
                    </a>
                  </div>
                  <div className="online--users__details">
                    <div className="online--users__data">
                      <div className="online--users__images">
                        <img
                          src="/images/user.png"
                          width="30px"
                          height="30px"
                          alt="user"
                        />
                        <img
                          src="/images/online.png"
                          width="7px"
                          height="7px"
                          alt="user"
                          className="online--users__status"
                        />
                      </div>
                      <span className="online--users__name">Atif Paracha</span>
                    </div>
                    <a className="online--users__remove">
                      <img
                        src="/images/icon-cross-modal.png"
                        width="10px"
                        height="10px"
                        alt="user"
                      />
                    </a>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
}
