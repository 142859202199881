import React from "react";
import { useState, useEffect } from "react";
import AppContext from "../Context/Context";
function NavigateURL() {
  useEffect(() => {
    RedirectMe();
  }, []);

  function RedirectMe() {
    window.location = AppContext.AuthWebLink  as any;
  }

  return <div></div>;
}

export default NavigateURL;
