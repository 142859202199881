import React from "react";
import AppContext from "../Context/Context";
import { useState, useEffect, useRef } from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";

import { useGlobalState } from "../Context/GlobalStateContext";
import axiosAuth from "../axios/Interceptors";
import TableGridView from "../App/DynamicGridView";
import { getEndOfMonth, getStartOfMonth } from "../Shared/Utility";
import TimeLogDetailView from "../App/TimeLogDetailView";
function TasksManagement() {
  const globalState = useGlobalState();
  const [screenID, setScreenID] = useState(0);

  const [showAnimation, setShowAnimation] = useState(false);
  const [showReportGrid, setShowReportGrid] = useState(false);
  const [reportGridData, setReportGridData] = useState<any>([]);

  const [filtersDropdownData, setFiltersDropdownData] = useState({
    ReportSectionData: [],
    ReportSectionButtonData: [],
    ProjectData: [],
    UserData: [],
  });

  const [selectedFiltersDropdownObject, setSelectedFiltersDropdownObject] =
    useState({
      projectid: 0,
      listid: 0,
      from: getStartOfMonth(),
      to: getEndOfMonth(),
      userid: 0,
      dateformate: 1,
    });

  useEffect(() => {
    getTasksManagementListsData();
  }, []);

  function getTasksManagementListsData() {
    let object = {} as any;
    object.masterbpid = 0;
    setShowAnimation(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/MGReporting/GetTasksManagementListsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          setFiltersDropdownData((prevState: any) => ({
            ...prevState,
            ProjectData: l_response[0],
            UserData: l_response[1],
          }));
        } else {
          console.log("error", response.data.Message);
        }
        setShowAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      <section className="msg--body">
        <div className="msg--body__lt ">
          <div className="msg--body__lt__head flex-d flex-ac">
            <div className="flex-d flex-ac">Tasks Management</div>
          </div>
          <div className="msg--body__messages p-all10 p-unset">
            <>
              <div className="flex-d flex-ac flex-gap10">
                {filtersDropdownData.ProjectData &&
                  filtersDropdownData.ProjectData.length > 1 && (
                    <>
                      <select
                        className="input--type__text"
                        value={selectedFiltersDropdownObject.projectid}
                        onChange={(e) =>
                          setSelectedFiltersDropdownObject(
                            (prevState: any) => ({
                              ...prevState,
                              projectid: e.target.value,
                            })
                          )
                        }
                      >
                        <option value={0}>All Projects</option>
                        {filtersDropdownData.ProjectData &&
                          filtersDropdownData.ProjectData.map(
                            (Item: any, index: any) => (
                              <option value={Item.PID} key={Item.PID}>
                                {Item.Name}
                              </option>
                            )
                          )}
                      </select>

                      <select
                        className="input--type__text"
                        value={selectedFiltersDropdownObject.userid}
                        onChange={(e) =>
                          setSelectedFiltersDropdownObject(
                            (prevState: any) => ({
                              ...prevState,
                              userid: e.target.value,
                            })
                          )
                        }
                      >
                        <option value={0}>All Users</option>
                        {filtersDropdownData.UserData &&
                          filtersDropdownData.UserData.map(
                            (Item: any, index: any) => (
                              <option value={Item.PID} key={Item.PID}>
                                {Item.Name}
                              </option>
                            )
                          )}
                      </select>
                    </>
                  )}

                <input
                  className="input--type__text"
                  placeholder="Start Date"
                  type="date"
                  value={selectedFiltersDropdownObject.from}
                  onChange={(e) =>
                    setSelectedFiltersDropdownObject((prevState: any) => ({
                      ...prevState,
                      from: e.target.value,
                    }))
                  }
                />

                <input
                  className="input--type__text"
                  placeholder="End Date"
                  type="date"
                  value={selectedFiltersDropdownObject.to}
                  onChange={(e) =>
                    setSelectedFiltersDropdownObject((prevState: any) => ({
                      ...prevState,
                      to: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="reuse-min-wid">
                <TimeLogDetailView
                  MGEventID={0}
                  SiteUserID={selectedFiltersDropdownObject.userid}
                  MGProjectID={selectedFiltersDropdownObject.projectid}
                  StartDateTime={selectedFiltersDropdownObject.from}
                  EndDateTime={selectedFiltersDropdownObject.to}
                  expandView={true}
                />
              </div>
            </>
          </div>
        </div>
      </section>
    </>
  );
}

export default TasksManagement;
