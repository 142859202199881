import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AppContext from "../../Context/Context";
import axiosAuth from "../../axios/Interceptors";
import moment from "moment";
import { GetSelectedIDsFromListWithComma } from "../../Shared/Utility";
import StatusView from "./StatusView";
import ProjectView from "./ProjectView";
import EventDetailsView from "./EventDetailsView";
import TasksComm from "./TasksComm";
import ResourceView from "./ResourceView";
import ElapsedTime from "../../App/ElapsedTime";
import LoadingAnimation from "../../LoadingAnimation/LoadingAnimation";
function UserList() {
  const [loadingState, setLoadingState] = useState(false);
  const [projectsListData, setProjectsListData] = useState<any>([]);

  useEffect(() => {
    getUserListData();
  }, []);

  function getUserListData() {
    let object = {} as any;
    setLoadingState(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/MGProject/GetMGSiteUserData",
        object
      )
      .then((response: any) => {
        if (response.data.Status === 1) {
          let result = response.data.Data;
          if (result != null && result.length > 0) {
            setProjectsListData(result[0]);
          }
        }
        setLoadingState(false);
      })
      .catch((error: any) => {
        console.error("There was an error!", error);
      });
  }

  function updateMGProjectStatus(siteuserid: any, lockedout: any) {
    let object = {} as any;
    object.siteuserid = siteuserid;
    object.lockedout = lockedout;
    axiosAuth
      .post(
        AppContext.AppBaseURL + "api/MGProject/UpdateMGSiteUserStatus",
        object
      )
      .then((response: any) => {
        if (response.data.Status === 1) {
          let result = response.data.Data;
          getUserListData();
        }
      })
      .catch((error: any) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {loadingState && <LoadingAnimation />}
      <div className="p-all10">
        <div className="overflow-auto">
          <table id="tblExportGrid" className="reuse-table">
            <thead>
              <tr>
                <th className="sticky-header">Name</th>
                <th className="sticky-header">Email</th>
                <th className="sticky-header col-xxs">Active</th>
              </tr>
            </thead>
            <tbody>
              {projectsListData &&
                projectsListData.map((Item: any, index: any) => (
                  <tr key={index}>
                    <td>{Item.Name}</td>
                    <td>{Item.Email}</td>
                    <td className="text-center col-xxs">
                      <input
                        className="input-check"
                        type="checkbox"
                        checked={Item.LockedOut == 0 ? true : false}
                        onChange={(e: any) =>
                          updateMGProjectStatus(
                            Item.PID,
                            e.target.checked == false ? 1 : 0
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default UserList;
