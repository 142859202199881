import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  checkIfItemIsIntheList,
  getUniqueRecordsAgainstNthColumn,
} from "../Shared/Utility";
type Props = {
  DataType: number;
  GridData: any;
  ColumnName: string;
  RefreshFilters: boolean;
  ClearFilters: boolean;
  GetFilteredLists: boolean;
  ColumnFilterCheckboxListCallback: any;
  ReturnAllFilterdLists: any;
  ClickPosition: any;
  Orientation: string;
  ColumnOrientation: any;
};
function ColumnFilterCheckboxList(props: Props) {
  const [listOfList, setListOfList] = useState<any>([]);
  const [currentList, setCurrentList] = useState<any>([]);
  const [currentListTemp, setCurrentListTemp] = useState<any>([]);
  const [refresh, setRefresh] = useState<any>(false);
  const [selectUnSelectAll, setSelectUnSelectAll] = useState<any>(true);
  const [searchedValue, setSearchedValue] = useState("");
  const [searchedValueArray, setSearchedValueArray] = useState<any>([]);
  const listOfListRef = useRef<any | null>(null);
  listOfListRef.current = listOfList;

  const gridDatatRef = useRef<any | null>(null);
  gridDatatRef.current = [...props.GridData];

  const [checboxListWidth, setChecboxListWidth] = useState(0);

  let excludeColumnsList = ["", "MENA Average", " "];

  const componentRef = useRef<any>(null);
  const [showList, setShowList] = useState<any>(false);

  const showListRef = useRef<any | null>(null);
  showListRef.current = showList;

  const [columnName, setColumnName] = useState(props.ColumnName);

  const [showOptions, setShowOptions] = useState(1);

  const [comparisionType, setComparisionType] = useState(1);
  const [comparisionValue, setComparisionValue] = useState(0);
  const [comparisionValueArray, setComparisionValueArray] = useState<any>([]);

  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        const functionName = event.target.getAttribute("data-function");
        if (functionName === null) {
          setShowList(false);
          showListRef.current = false;
        } else {
          setShowList(true);
          showListRef.current = true;
        }
      } else {
        setShowList(true);
        showListRef.current = true;
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (props.ColumnName.includes("(Option2)")) {
      //(Option2) shows the textbox with comparision operators
      setShowOptions(2);
      let found = false;
      if (comparisionValueArray.length > 0) {
        for (let i = 0; i < comparisionValueArray.length; i++) {
          if (comparisionValueArray[i].Name === props.ColumnName) {
            setComparisionValue(comparisionValueArray[i].Value);
            setComparisionType(comparisionValueArray[i].Type);
            found = true;
            break;
          }
        }
        if (!found) {
          setComparisionValue(0);
          setComparisionType(1);
        }
      }
      setColumnName(props.ColumnName.replaceAll("(Option2)", ""));
    } else {
      //normal filtering
      setShowOptions(1);
      setColumnName(props.ColumnName);
    }
  }, [props.ColumnName]);

  useEffect(() => {
    if (columnName == "") {
      setShowList(false);
      showListRef.current = false;
    } else {
      setShowList(true);
      showListRef.current = true;
    }
  }, [columnName]);

  useEffect(() => {
    let l_checboxListWidth: any =
      document.getElementById("checklistid")?.clientWidth;

    if (props.Orientation === "Center") {
      l_checboxListWidth = l_checboxListWidth / 2;
    } else if (props.Orientation === "Right") {
      l_checboxListWidth = l_checboxListWidth;
    } else if (props.Orientation === "Left") {
      l_checboxListWidth = 0;
    }

    if (
      props.ColumnOrientation &&
      props.ColumnOrientation.length &&
      props.ColumnName !== ""
    ) {
      for (let i = 0; i < props.ColumnOrientation.length; i++) {
        if (props.ColumnName === props.ColumnOrientation[i].Name) {
          l_checboxListWidth =
            document.getElementById("checklistid")?.clientWidth;
          if (props.ColumnOrientation[i].Orientation === "Center") {
            l_checboxListWidth = l_checboxListWidth / 2;
          } else if (props.ColumnOrientation[i].Orientation === "Right") {
            l_checboxListWidth = l_checboxListWidth;
          } else if (props.ColumnOrientation[i].Orientation === "Left") {
            l_checboxListWidth = 0;
          }
        }
      }
    }

    setChecboxListWidth(l_checboxListWidth);
    if (searchedValueArray.length > 0) {
      for (let i = 0; i < searchedValueArray.length; i++) {
        if (searchedValueArray[i].Name === props.ColumnName) {
          setSearchedValue(searchedValueArray[i].Value);
          break;
        }
      }
    }

    filterByKeyUp();
  }, [currentList]);

  useEffect(() => {
    setSearchedValue("");
    makeLists();
  }, [props.ColumnName, props.GridData]);

  useEffect(() => {
    if (props.GridData && props.GridData.length > 0) {
      filterData();
    }
  }, [props.RefreshFilters]);

  useEffect(() => {
    setListOfList([]);
    setSearchedValue("");
    setSearchedValueArray("");
  }, [props.ClearFilters]);

  function makeLists() {
    let listFound = false;
    if (
      props.ColumnName !== "" &&
      props.GridData &&
      props.GridData.length > 0
    ) {
      setRefresh(!refresh);
      for (let i = 0; i < listOfList.length; i++) {
        if (listOfList[i].Name === props.ColumnName) {
          listFound = true;

          const deepCopy1 = JSON.parse(JSON.stringify(listOfList[i].List));
          const deepCopy2 = JSON.parse(JSON.stringify(listOfList[i].List));

          setCurrentList(deepCopy1);
          setCurrentListTemp(deepCopy2);
          checkSelectUnselectAll(deepCopy1);

          // setCurrentList([...listOfList[i].List]);
          // setCurrentListTemp([...listOfList[i].List]);
          // checkSelectUnselectAll(listOfList[i].List);
          break;
        } else {
          listFound = false;
        }
      }
      if (!listFound) {
        let newList = getUniqueRecordsAgainstNthColumn(
          props.GridData,
          props.ColumnName
        );
        if (newList && newList.length > 0) {
          let tempList = [];
          for (let i = 0; i < newList.length; i++) {
            let obj = {} as any;
            obj.Name = newList[i];
            obj.IsSelected = true;
            tempList.push(obj);
          }

          let obj = {} as any;
          obj.Name = props.ColumnName;
          obj.List = tempList;
          setListOfList((prevArray: any) => [...prevArray, obj]);

          const deepCopy1 = JSON.parse(JSON.stringify(tempList));
          const deepCopy2 = JSON.parse(JSON.stringify(tempList));
          setCurrentList(deepCopy1);
          setCurrentListTemp(deepCopy2);
          checkSelectUnselectAll(deepCopy1);
          // setCurrentList([...tempList]);
          // setCurrentListTemp([...tempList]);
          //checkSelectUnselectAll(tempList);
        }
      }
    }
  }

  function updateListdata(index: number, name: any) {
    const l_currentList = [...currentListTemp];
    l_currentList[index].IsSelected = !l_currentList[index].IsSelected;

    const deepCopy1 = JSON.parse(JSON.stringify(l_currentList));
    const deepCopy2 = JSON.parse(JSON.stringify(l_currentList));

    checkSelectUnselectAll(deepCopy1);
    setCurrentListTemp(deepCopy1);

    //checkSelectUnselectAll(l_currentList);
    //setCurrentListTemp([...l_currentList]);
    //setCurrentList(l_currentList);
  }

  function checkSelectUnselectAll(l_currentList: any) {
    let allChecked = true;
    for (let i = 0; i < l_currentList.length; i++) {
      if (l_currentList[i].IsSelected === false) {
        allChecked = false;
        break;
      }
    }
    setSelectUnSelectAll(allChecked);
  }

  function selectUnselectAll(event: any) {
    const deepCopy1 = JSON.parse(JSON.stringify(currentListTemp));
    const l_currentList = deepCopy1;
    //const l_currentList = [...currentListTemp];
    if (event.target.checked) {
      //select all
      setSelectUnSelectAll(true);
      for (let i = 0; i < l_currentList.length; i++) {
        l_currentList[i].IsSelected = true;
      }
    } else {
      //unselect all
      setSelectUnSelectAll(false);
      for (let i = 0; i < l_currentList.length; i++) {
        l_currentList[i].IsSelected = false;
      }
    }
    setRefresh(!refresh);

    const deepCopy2 = JSON.parse(JSON.stringify(l_currentList));
    setCurrentListTemp(deepCopy2);

    //setCurrentList(l_currentList);
    // setCurrentListTemp([...l_currentList]);
  }

  function filterData() {
    let alreadyPush = false;
    let l_searchedValueArray = [...searchedValueArray];

    for (let i = 0; i < l_searchedValueArray.length; i++) {
      if (l_searchedValueArray[i].Name === props.ColumnName) {
        alreadyPush = true;
        l_searchedValueArray[i].Value = searchedValue;
      }
    }

    if (alreadyPush === false) {
      l_searchedValueArray.push({
        Name: props.ColumnName,
        Value: searchedValue,
      });
    }

    setSearchedValueArray(l_searchedValueArray);

    let thisGridData = [...gridDatatRef.current];
    for (let index = 0; index < thisGridData.length; index++) {
      thisGridData[index].IsExcluded = false;
    }

    // for (let i = 0; i < listOfListRef.current.length; i++) {
    //   for (let j = 0; j < listOfListRef.current[i].List.length; j++) {
    //     for (let k = 0; k < thisGridData.length; k++) {
    //       if (
    //         thisGridData[k][listOfListRef.current[i].Name] ===
    //           listOfListRef.current[i].List[j].Name &&
    //         listOfListRef.current[i].List[j].IsSelected === false
    //       ) {
    //         thisGridData[k].IsExcluded = true;
    //       }
    //     }
    //   }
    // }
    const deepCopy1 = JSON.parse(JSON.stringify(currentListTemp));
    const deepCopy2 = JSON.parse(JSON.stringify(currentList));

    for (let index = 0; index < deepCopy2.length; index++) {
      deepCopy2[index].IsSelected = false;
    }
    let tempList = [];
    if (searchedValue === "") {
      tempList = deepCopy1;
    } else {
      for (let i = 0; i < deepCopy1.length; i++) {
        for (let j = 0; j < deepCopy2.length; j++) {
          if (deepCopy2[j].Name === deepCopy1[i].Name) {
            deepCopy2[j].IsSelected = deepCopy1[i].IsSelected;
          }
        }
      }

      tempList = deepCopy2;
    }

    let tempListOfList = [];
    const deepCopyListOfList = JSON.parse(JSON.stringify(listOfList));
    tempListOfList = deepCopyListOfList;
    //tempListOfList = listOfList;
    for (let i = 0; i < tempListOfList.length; i++) {
      if (tempListOfList[i].Name === props.ColumnName) {
        const deepCopy1 = JSON.parse(JSON.stringify(tempList));
        tempListOfList[i].List = deepCopy1;
        // tempListOfList[i].List = [...currentList];
        setListOfList(tempListOfList);
        listOfListRef.current = tempListOfList;
        break;
      }
    }

    for (let i = 0; i < listOfListRef.current.length; i++) {
      for (let j = 0; j < listOfListRef.current[i].List.length; j++) {
        for (let k = 0; k < thisGridData.length; k++) {
          if (
            thisGridData[k][listOfListRef.current[i].Name] ===
              listOfListRef.current[i].List[j].Name &&
            listOfListRef.current[i].List[j].IsSelected === false
          ) {
            thisGridData[k].IsExcluded = true;
          }
        }
      }
    }

    props.ColumnFilterCheckboxListCallback(thisGridData);
  }

  // function filterData() {
  //   let tempListOfList = [];
  //   tempListOfList = listOfList;
  //   for (let i = 0; i < tempListOfList.length; i++) {
  //     if (tempListOfList[i].Name === props.ColumnName) {
  //       tempListOfList[i].List = currentList;
  //       setListOfList(tempListOfList);
  //       listOfListRef.current = tempListOfList;
  //       break;
  //     }
  //   }

  //   let thisGridData = [...gridDatatRef.current];
  //   for (let index = 0; index < thisGridData.length; index++) {
  //     thisGridData[index].IsExcluded = false;
  //   }

  //   for (let i = 0; i < listOfListRef.current.length; i++) {
  //     for (let j = 0; j < listOfListRef.current[i].List.length; j++) {
  //       for (let k = 0; k < thisGridData.length; k++) {
  //         if (
  //           thisGridData[k][listOfListRef.current[i].Name] ===
  //             listOfListRef.current[i].List[j].Name &&
  //           listOfListRef.current[i].List[j].IsSelected === false
  //         ) {
  //           thisGridData[k].IsExcluded = true;
  //         }
  //       }
  //     }
  //   }

  //   props.ColumnFilterCheckboxListCallback(thisGridData);
  // }

  function filterDataComparision() {
    let alreadyPush = false;
    let l_searchedValueArray = [...comparisionValueArray];

    for (let i = 0; i < l_searchedValueArray.length; i++) {
      if (l_searchedValueArray[i].Name === props.ColumnName) {
        alreadyPush = true;
        l_searchedValueArray[i].Value = comparisionValue;
        l_searchedValueArray[i].Type = comparisionType;
      }
    }

    if (alreadyPush === false) {
      l_searchedValueArray.push({
        Name: props.ColumnName,
        Value: comparisionValue,
        Type: comparisionType,
      });
    }

    setComparisionValueArray(l_searchedValueArray);

    let thisGridData = [...gridDatatRef.current];
    for (let index = 0; index < thisGridData.length; index++) {
      //thisGridData[index].IsExcluded = false;
    }

    for (let i = 0; i < thisGridData.length; i++) {
      // if (columnName == thisGridData[i][columnName]) {
      if (comparisionType == 1) {
        if (
          thisGridData[i][columnName] > comparisionValue ||
          thisGridData[i][columnName] == comparisionValue
        ) {
          thisGridData[i].IsExcluded = false;
        } else {
          thisGridData[i].IsExcluded = true;
        }
      } else if (comparisionType == 2) {
        if (thisGridData[i][columnName] > comparisionValue) {
          thisGridData[i].IsExcluded = false;
        } else {
          thisGridData[i].IsExcluded = true;
        }
      }
      if (comparisionType == 3) {
        if (
          thisGridData[i][columnName] < comparisionValue ||
          thisGridData[i][columnName] == comparisionValue
        ) {
          thisGridData[i].IsExcluded = false;
        } else {
          thisGridData[i].IsExcluded = true;
        }
      }
      if (comparisionType == 4) {
        if (thisGridData[i][columnName] < comparisionValue) {
          thisGridData[i].IsExcluded = false;
        } else {
          thisGridData[i].IsExcluded = true;
        }
      }
      // }
    }

    props.ColumnFilterCheckboxListCallback(thisGridData);
  }

  useEffect(() => {
    if (listOfList.length > 0) {
      // props.ReturnAllFilterdLists(listOfList);
    }
  }, [props.GetFilteredLists]);

  useEffect(() => {
    if (searchedValue !== "") {
    } else {
      const deepCopy1 = JSON.parse(JSON.stringify(currentList));
      setCurrentListTemp(deepCopy1);
    }
    filterByKeyUp();
  }, [searchedValue]);

  function filterByKeyUp() {
    let filterArray: any = [];
    for (let i = 0; i < listOfListRef.current.length; i++) {
      if (listOfListRef.current[i].Name === props.ColumnName) {
        if (searchedValue === "") {
          setCurrentListTemp(listOfListRef.current[i].List);
        }

        filterArray = filterByValue(
          listOfListRef.current[i].List,
          searchedValue
        );
      }
    }

    ///setCurrentList(filterArray);

    const deepCopy1 = JSON.parse(JSON.stringify(filterArray));
    // const deepCopy2 = JSON.parse(JSON.stringify(filterArray));

    setCurrentListTemp(deepCopy1);
    checkSelectUnselectAll(deepCopy1);
  }

  function filterByValue(array: any, value: string) {
    return array.filter(
      (data: any) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  return (
    <div
      ref={componentRef}
      id="checkboxFilterList"
      className={`${
        showListRef.current === false
          ? "no-display table-filter-drop"
          : "show-display table-filter-drop"
      }`}
      style={{
        position: "absolute",
        top: props.ClickPosition.y + 18,
        left: props.ClickPosition.x + checboxListWidth,
      }}
    >
      <div className="check-list" id="checklistid">
        {showOptions === 1 && (
          <>
            <input
              className="input--type__text"
              value={searchedValue}
              onChange={(e: any) => setSearchedValue(e.target.value)}
              placeholder="Search"
            />

            <div className="filter-scroll grid-d flex-gap5 mt--10">
              {currentListTemp && currentListTemp.length > 0 && (
                <div className="flex-d flex-ac flex-gap10">
                  <input
                    className="input-check"
                    type="checkbox"
                    value={selectUnSelectAll}
                    checked={selectUnSelectAll}
                    onChange={(e: any) => selectUnselectAll(e)}
                  />
                  <span className="no-wrap">Select/UnSelect All</span>
                </div>
              )}

              {currentListTemp &&
                currentListTemp.map((item: any, index: any) => (
                  <React.Fragment key={props.ColumnName + index}>
                    {!checkIfItemIsIntheList(item.Name, excludeColumnsList) && (
                      <div className="flex-d flex-ac flex-gap10">
                        <input
                          className="input-check"
                          type="checkbox"
                          value={item.IsSelected}
                          checked={item.IsSelected}
                          onChange={(e: any) =>
                            updateListdata(index, item.Name)
                          }
                        />
                        <span className="no-wrap">{item.Name}</span>
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </div>
            <div className="modal--button mt--20 flex-d flex-jc">
              <a onClick={() => filterData()}>Filter</a>
            </div>
          </>
        )}

        {showOptions === 2 && (
          <>
            <input
              className="input-search"
              value={comparisionValue}
              onChange={(e: any) => setComparisionValue(e.target.value)}
              placeholder="Search"
              type="number"
            />

            <div className="filter-scroll grid-d flex-gap5 mt--10">
              <div className="flex-d flex-ac flex-gap10">
                <input
                  className="input-check"
                  type="checkbox"
                  checked={comparisionType == 1}
                  onClick={() => setComparisionType(1)}
                  name="Comparision"
                />
                <span>&gt;=</span>
              </div>
              <div className="flex-d flex-ac flex-gap10">
                <input
                  className="input-check"
                  type="checkbox"
                  checked={comparisionType == 2}
                  onClick={() => setComparisionType(2)}
                  name="Comparision"
                />
                <span>&gt;</span>
              </div>
              <div className="flex-d flex-ac flex-gap10">
                <input
                  className="input-check"
                  type="checkbox"
                  checked={comparisionType == 3}
                  onClick={() => setComparisionType(3)}
                  name="Comparision"
                />
                <span>&lt;=</span>
              </div>
              <div className="flex-d flex-ac flex-gap10">
                <input
                  className="input-check"
                  type="checkbox"
                  checked={comparisionType == 4}
                  onClick={() => setComparisionType(4)}
                  name="Comparision"
                />
                <span>&lt;</span>
              </div>
            </div>

            <div className="help-btns p-relative">
              <a onClick={() => filterDataComparision()} className="cancel">
                Filter
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ColumnFilterCheckboxList;
