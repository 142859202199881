import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AppContext from "../../Context/Context";
import axiosAuth from "../../axios/Interceptors";
import TasksComm from "./TasksComm";
import TimerManagement from "../../App/Timermanagement";
import DisplaySpentTime from "../../App/DisplaySpentTime";
import ConfirmationModal from "../../App/ConfirmationModal";
import { useGlobalState } from "../../Context/GlobalStateContext";
import { Modal } from "react-bootstrap";
import LoadingAnimation from "../../LoadingAnimation/LoadingAnimation";
import { formateDate, getTimeDifferenceHHMM } from "../../Shared/Utility";
import moment from "moment";
import TimeLogDetailView from "../../App/TimeLogDetailView";
import ManualEntriesModal from "../../App/ManualEntriesModal";

type Props = {
  MGWorkspaceID: any;
  EventDetails: any;
  handleChildClick: Function;
};
export default function EventDetailsView(props: Props) {
  const globalState = useGlobalState();
  const [loadingState, setLoadingState] = useState(false);
  const [expandView, setExpandView] = useState(false);
  const [showLogDetail, setShowLogDetail] = useState(false);
  const [showManualEntriesModal, setManualEntriesModal] = useState(false);
  const [showExpandChatG, setshowExpandChatG] = useState(false);

  const [showTicket, setShowTicket] = useState(false);
  const [supportTicketData, setSupportTicketData] = useState<any>([]);

  const ShowHideExpandG = () => {
    setshowExpandChatG(!showExpandChatG);
  };
  const [showExpandChatI, setshowExpandChatI] = useState(false);
  const ShowHideExpandI = () => {
    setshowExpandChatI(!showExpandChatI);
  };

  const [filterData, setFilterData] = useState({
    MGMessageCategoryID: 8,
    MGMessageChannelID: 0,
    ShowInternalChat: false,
    ShowGeneralChat: true,
  });

  useEffect(() => {
    setFilterData((prevState: any) => ({
      ...prevState,
      ShowInternalChat: false,
      ShowGeneralChat: true,
    }));
    setShowLogDetail(false);
  }, [props.EventDetails]);

  function CreateChannel() {
    let object: any = {};
    object.typeid = 1;
    object.mgworkspaceid = props.MGWorkspaceID;
    object.mgmessagechannelid = 0;
    object.mgmessagecategoryid = filterData.MGMessageCategoryID;
    object.permissiontype = 1;
    object.title = props.EventDetails.Name;
    object.description = props.EventDetails.Description;
    object.originalchannelid = 0;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGMessage/UpdateChannelInfo", object)
      .then((response) => {
        if (response.data.Status === 1) {
          var l_MessageChannel =
            response.data.Data[response.data.Data.length - 1];
          let MGMessageChannelID = 0;
          if (l_MessageChannel.length > 0) {
            MGMessageChannelID = l_MessageChannel[0]["MGMessageChannelID"];
            MGEventUpdateCustom(3, MGMessageChannelID);
          }
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function MGEventUpdateCustom(type: number, value: number) {
    let object = {} as any;
    object.mgeventid = props.EventDetails.MGEventID;
    object.type = type;
    object.value = value;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGEvent/MGEventUpdateCustom", object)
      .then((response) => {
        if (response.data.Status === 1) {
          if (type == 3) {
            props.handleChildClick(3, props.EventDetails);
          } else {
            props.handleChildClick(8, {});
          }
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        MGEventUpdateCustom(obj.SelectedID, obj.OtherID);
      }
    }
    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
      SelectedID: 0,
      OtherID: 0,
      Object: {},
    });
  }

  function getSupportTicketByMGSupportTicketID(mgsupportticketid: any) {
    let object = {} as any;
    object.mgsupportticketid = mgsupportticketid;
    setLoadingState(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/MGSupportTicket/MGSupportTicketGetSupportTicketByMGSupportTicketID",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var l_response = response.data.Data;
          setSupportTicketData(l_response);
          console.log(l_response);
          setShowTicket(true);
        } else {
          console.log("error", response.data.Message);
        }
        setLoadingState(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      {loadingState && <LoadingAnimation />}

      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />
      {/* {props.EventDetails.Name} */}
      {props.EventDetails.Name && props.EventDetails.Name != "" && (
        <div
          className={`task-detail-fix custom--scroll ${
            expandView ? "expand-view" : ""
          }`}
        >
          <div className="flex-d flex-ac flex-jsb p-all10">
            {!expandView && (
              <a
                className="wh-24"
                onClick={(e) => {
                  setExpandView(!expandView);
                  setFilterData((prevState: any) => ({
                    ...prevState,
                    ShowInternalChat: true,
                    ShowGeneralChat: true,
                  }));
                }}
              >
                <>
                  <img
                    title="Expand View"
                    src="/images/rightg.png"
                    alt="img"
                    width="8px"
                    height="16px"
                    className="rotate-180d"
                  />
                </>
              </a>
            )}
            {expandView && (
              <a
                className="wh-24"
                onClick={(e) => {
                  setExpandView(!expandView);
                  setFilterData((prevState: any) => ({
                    ...prevState,
                    ShowInternalChat: false,
                  }));
                }}
              >
                <>
                  <img
                    title="Compress View"
                    src="/images/rightg.png"
                    alt="img"
                    width="8px"
                    height="16px"
                  />
                </>
              </a>
            )}
            <div className="flex-d flex-ac flex-gap10">
              <a
                title="Delete"
                onClick={() =>
                  globalState.setConfirmationModalObject({
                    ShowPopup: true,
                    Heading: "Alert",
                    Message: "Are your sure, you want to delete this record?",
                    ID: 1,
                    SelectedID: 5,
                    OtherID: 1,
                  })
                }
              >
                <img
                  src="/images/delete.png"
                  alt="img"
                  width="20px"
                  height="20px"
                />
              </a>
              <a
                title="Edit"
                onClick={() => {
                  props.handleChildClick(7, props.EventDetails);
                }}
              >
                <img
                  src="/images/edit.png"
                  alt="img"
                  width="20px"
                  height="20px"
                />
              </a>
              <a
                title="Close"
                onClick={(e) => {
                  setExpandView(false);
                  props.handleChildClick(2, {});
                }}
              >
                <img
                  src="/images/icon-cross.png"
                  width="20px"
                  height="20px"
                  alt="attachment"
                />
              </a>
            </div>
          </div>
          <div className="timer-ss mt--20">
            <TimerManagement
              MGWorkspaceID={props.MGWorkspaceID}
              EventDetails={props.EventDetails}
              HandleChildClick={props.handleChildClick}
            />
          </div>
          <div className="flex-d flex-ac flex-jc time-total">
            <DisplaySpentTime
              Type={1}
              TimeSpent={props.EventDetails.ActualTimeSpent}
            />
          </div>
          <div className="flex-d flex-ac flex-jse flex-gap10 p-all10">
            {props.EventDetails.MGEventTypeID == 3 &&
              props.EventDetails.MGSupportTicketID > 0 && (
                <a
                  onClick={() =>
                    getSupportTicketByMGSupportTicketID(
                      props.EventDetails.MGSupportTicketID
                    )
                  }
                  title="Support Ticket Detail"
                >
                  <img
                    src="/images/ticket.svg"
                    alt="img"
                    width={24}
                    height={24}
                  />
                </a>
              )}

            <a
              onClick={() => setShowLogDetail(!showLogDetail)}
              title={showLogDetail == false ?"Show Logs":"Hide Logs"}
            >
              <img src="/images/logs.svg" alt="img" width={24} height={24} />
            </a>
            <a
              onClick={() => setManualEntriesModal(!showManualEntriesModal)}
              title="Click to log time for this task"
            >
              <img src="/images/timer.svg" alt="img" width={24} height={24} />
            </a>
          </div>
          {showLogDetail &&<TimeLogDetailView
            MGEventID={props.EventDetails.MGEventID}
            SiteUserID={0}
            MGProjectID={0}
            StartDateTime={new Date()}
            EndDateTime={new Date()}
            expandView={expandView}
          />}

          <ManualEntriesModal
            EventDetails={props.EventDetails}
            ShowModal={showManualEntriesModal}
          />

          <>
            <div
              className={`ib-display w-100 p-lr10 font-14 mt--15 ${
                filterData.ShowGeneralChat || filterData.ShowInternalChat
                  ? "nev-ycs"
                  : "nev-nncs"
              } ${expandView ? "nev-nncs2" : ""} `}
            >
              <span className="flex-d task-name">
                {props.EventDetails.Name}
              </span>
              <span className="flex-d task-descp">
                {props.EventDetails.Description}
              </span>
              <div className="flex-d flex-ac flex-jse flex-gap10 mt--15">
                <span className="flex-d flex-ac flex-gap5 sm-greyfont">
                  <img
                    src="/images/project.svg"
                    alt="img"
                    width={13}
                    height={13}
                  />
                  {props.EventDetails.MGProjectName}
                </span>
                <span className="flex-d flex-ac flex-gap5 sm-greyfont">
                  <img
                    src="/images/status.svg"
                    alt="img"
                    width={13}
                    height={13}
                  />
                  {props.EventDetails.MGStatusName}
                </span>
                <span className="flex-d flex-ac flex-gap5 sm-greyfont">
                  <img
                    src="/images/user.svg"
                    alt="img"
                    width={13}
                    height={13}
                  />
                  {props.EventDetails.AssignedTo}
                </span>
              </div>
            </div>
            <div
              className={`grid-d flex-gap15 ${
                expandView ? "fr-11 p-lr10" : "fr-1"
              } ${showExpandChatG ? "fr1--imp" : ""} ${
                showExpandChatI ? "fr1--imp" : ""
              } ${
                props.EventDetails.InternalChannelCode == "" && expandView
                  ? "fr1--imp"
                  : ""
              }`}
            >
              {!showExpandChatI && (
                <div className="ib-display w-100 p-relative hide--empty h-40">
                  <div
                    className={`flex-d flex-jsb flex-ac m-accord ${
                      expandView && !filterData.ShowGeneralChat
                        ? "s-ab-close"
                        : ""
                    }`}
                  >
                    <span>General</span>
                    <div className="flex-d flex-ac flex-gap10">
                      {props.EventDetails.InternalChannelCode == "" &&
                        expandView && (
                          <a
                            className="btn-internal"
                            onClick={() => {
                              CreateChannel();
                            }}
                          >
                            Click Here Start Internal Chat
                          </a>
                        )}
                      <a
                        className="flex-d flex-ac flex-jsb"
                        onClick={(e) => {
                          let ShowGeneralChat = !filterData.ShowGeneralChat;
                          setFilterData((prevState: any) => ({
                            ...prevState,
                            ShowGeneralChat: ShowGeneralChat,
                          }));
                        }}
                      >
                        {props.EventDetails.GeneralChannelCode != "" &&
                        filterData.ShowGeneralChat ? (
                          <img
                            src="/images/closechat.svg"
                            alt="img"
                            width="16px"
                            height="4px"
                          />
                        ) : (
                          <img
                            src="/images/openchat.svg"
                            alt="img"
                            width="16px"
                            height="16px"
                          />
                        )}
                      </a>
                      {expandView && (
                        <a
                          className="flex-d flex-ac flex-jsb"
                          onClick={ShowHideExpandG}
                        >
                          {showExpandChatG ? (
                            <img
                              src="/images/shrink.svg"
                              alt="img"
                              width="16px"
                              height="16px"
                            />
                          ) : (
                            <img
                              src="/images/expand.svg"
                              alt="img"
                              width="16px"
                              height="16px"
                            />
                          )}
                        </a>
                      )}
                    </div>
                  </div>

                  {props.EventDetails.GeneralChannelCode != "" &&
                    filterData.ShowGeneralChat && (
                      <div className="task-chat-sm">
                        <TasksComm
                          workspaceid={props.MGWorkspaceID}
                          chennelid={props.EventDetails.GeneralChannelCode}
                        />
                      </div>
                    )}
                </div>
              )}
              {!showExpandChatG && (
                <div className="ib-display w-100 p-relative hide--empty h-40">
                  {props.EventDetails.InternalChannelCode != "" &&
                    expandView && (
                      <>
                        <div
                          className={`flex-d flex-jsb flex-ac m-accord ${
                            expandView && !filterData.ShowInternalChat
                              ? "s-ab-close"
                              : ""
                          }`}
                        >
                          <span>Internal</span>
                          <div className="flex-d flex-ac flex-gap10">
                            <a
                              className="flex-d flex-ac flex-jsb"
                              onClick={(e) => {
                                let ShowInternalChat =
                                  !filterData.ShowInternalChat;
                                setFilterData((prevState: any) => ({
                                  ...prevState,
                                  ShowInternalChat: ShowInternalChat,
                                }));
                              }}
                            >
                              {props.EventDetails.InternalChannelCode != "" &&
                              filterData.ShowInternalChat ? (
                                <img
                                  src="/images/closechat.svg"
                                  alt="img"
                                  width="16px"
                                  height="4px"
                                />
                              ) : (
                                <img
                                  src="/images/openchat.svg"
                                  alt="img"
                                  width="16px"
                                  height="16px"
                                />
                              )}
                            </a>
                            {expandView && (
                              <a
                                className="flex-d flex-ac flex-jsb"
                                onClick={ShowHideExpandI}
                              >
                                {showExpandChatI ? (
                                  <img
                                    src="/images/shrink.svg"
                                    alt="img"
                                    width="16px"
                                    height="16px"
                                  />
                                ) : (
                                  <img
                                    src="/images/expand.svg"
                                    alt="img"
                                    width="16px"
                                    height="16px"
                                  />
                                )}
                              </a>
                            )}
                          </div>
                        </div>
                        {props.EventDetails.InternalChannelCode != "" &&
                          filterData.ShowInternalChat && (
                            <div className="task-chat-sm">
                              <TasksComm
                                workspaceid={props.MGWorkspaceID}
                                chennelid={
                                  props.EventDetails.InternalChannelCode
                                }
                              />
                            </div>
                          )}
                      </>
                    )}
                </div>
              )}
            </div>
          </>

          {showTicket && (
            <Modal show={true}>
              <Modal.Header closeButton onClick={() => setShowTicket(false)}>
                <Modal.Title>Support Ticket</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <table className="reuse-table">
                    <thead>
                      <tr>
                        <th className="reused-tdlg-min">
                          <div className="flex-d flex-ac flex-jc flex-gap20">
                            <div className="flex-d flex-ac flex-gap10">
                              Summary
                            </div>
                          </div>
                        </th>
                        <th className="reused-tdsm">Status</th>
                        <th className="reused-tdsm">Assignee</th>
                        <th className="reused-tdsm">ETA</th>

                        <th className="reused-tdsm">Priority</th>
                      </tr>
                    </thead>
                    <tbody>
                      {supportTicketData[0] &&
                        supportTicketData[0].map((item: any, index: any) => (
                          <React.Fragment key={index}>
                            {!item.IsExcluded && (
                              <tr className="cursor-p">
                                <td className="reused-tdlg-min">
                                  {item.Summary}
                                </td>
                                <td className="reused-tdsm">
                                  <div className="flex-d">
                                    <span
                                      className="status-box flex-d flex-ac bold-font text-uppercase"
                                      style={{ background: item.StatusColor }}
                                    >
                                      {item.MGSupportTicketStatusName}
                                    </span>
                                  </div>
                                </td>

                                <td className="reused-tdsm">
                                  {item.RaisedByFirstName}
                                </td>

                                <td className="reused-tdsm">
                                  {formateDate(item.ETADateTime, false)}
                                </td>

                                <td className="reused-tdsm">
                                  <div className="flex-d flex-ac flex-gap7">
                                    <span
                                      className="priority-box flex-d"
                                      style={{ background: item.PriorityColor }}
                                    ></span>
                                    <span className="bold-font text-uppercase">
                                      {item.MGApplicationPriorityName}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="modal--button del--modal__btn">
                  <a onClick={() => setShowTicket(false)}>Close</a>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
      )}
    </>
  );
}
