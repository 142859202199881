import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AppContext from "../../Context/Context";
import axiosAuth from "../../axios/Interceptors";
import moment from "moment";
import {
  GetSelectedIDsFromListWithComma,
  SortTheList,
} from "../../Shared/Utility";
type Props = {
  ResourceList: [];
  TasksList: [];
  handleChildClick: Function;
};
export default function ResourceView(props: Props) {
  const [SortedTasksList, SetSortedTasksList] = useState<any>([]);

  useEffect(() => {
    let SortedTasksList = SortTheList(props.TasksList, "ResourceOrder", "A");
    SetSortedTasksList(SortedTasksList);
  }, [props.TasksList]);

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    MGEventID: string
  ) => {
    e.dataTransfer.setData("MGEventID", MGEventID);
  };
  const handleDrop = (
    e: React.DragEvent<HTMLDivElement>,
    targetCardId: number
  ) => {
    e.preventDefault();
    const MGEventID = Number(e.dataTransfer.getData("MGEventID"));
    MGEventUpdateCustom(6, MGEventID, targetCardId);
  };
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  function MGEventUpdateCustom(type: number, MGEventID: number, value: number) {
    let object = {} as any;
    object.mgeventid = MGEventID;
    object.type = type;
    object.value = value;
    axiosAuth
      .post(AppContext.AppBaseURL + "api/MGEvent/MGEventUpdateCustom", object)
      .then((response) => {
        if (response.data.Status === 1) {
          props.handleChildClick(3, {});
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
      <div className="grid-d w-100">
        <div className="flex-d custom--scroll flex-gap30 p--b15 p-lr10">
          {props.ResourceList &&
            props.ResourceList.map((item: any, index: any) => (
              <div
                className="task-board"
                key={"resource" + index}
                onDrop={(e) => handleDrop(e, item.SiteUserID)}
                onDragOver={handleDragOver}
              >
                <React.Fragment>
                  <>
                    <span className="task-head">
                      {item.Name}
                      <a
                        onClick={() => {
                          props.handleChildClick(4, item);
                        }}
                        title="Add Task"
                      >
                        <img
                          src="/images/plus.png"
                          alt="Add Task"
                          width="18px"
                          height="18px"
                        />
                      </a>
                    </span>

                    <div
                      className="task-list custom--scroll grid-d
                    flex-gap15"
                    >
                      {SortedTasksList &&
                        SortedTasksList.map((taskItem: any, taskIndex: any) => (
                          <React.Fragment key={"tasklist" + taskIndex}>
                            {item.SiteUserID == taskItem.SiteUserID && (
                              <div
                                className="s-tasks"
                                onClick={(e) => {
                                  props.handleChildClick(1, taskItem);
                                }}
                                draggable
                                onDragStart={(e) =>
                                  handleDragStart(e, taskItem.MGEventID)
                                }
                              >
                                <span className="flex-d">{taskItem.Name}</span>
                                {/* <span className="flex-d">
                                  {taskItem.Description}
                                </span> */}
                                <span className="flex-d flex-jse asgn-task flex-gap10">
                                  {taskItem.MGEventTypeID == 3 && (
                                    <img
                                      src="/images/ticket.svg"
                                      alt="Add Task"
                                      width="18px"
                                      height="18px"
                                    />
                                  )}
                                  {taskItem.AssignedTo}
                                </span>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  </>
                </React.Fragment>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
