import AppContext from "../Context/Context";
import $ from "jquery";
import * as XLSX from "xlsx";
import moment from "moment";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
export function WriteAndDownloadFile(filename: string, content: string) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(content)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export async function getFileFromUrl(
  url: any,
  name: any,
  defaultType = "image/jpeg"
) {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

export function ConvertSecondsIntoDayTimeFormat(Seconds: any) {
  var DisplayTime = "";
  var TimeDistance = Seconds * 1000; // Convert to ms
  if (Seconds <= 0) {
    DisplayTime = "0d:0h:0m:0s";
  } else {
    //calculations for days, hours, minutes and seconds
    var days = Math.floor(TimeDistance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (TimeDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((TimeDistance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((TimeDistance % (1000 * 60)) / 1000);

    DisplayTime = days + "d " + hours + "h " + minutes + "m " + seconds + "s";
  }
  return DisplayTime;
}

export function ConvertSecondsIntoTimeFormat(Seconds: any) {
  var DisplayTime = "";
  var TimeDistance = Seconds * 1000; // Convert to ms
  if (Seconds <= 0) {
    DisplayTime = "00:00:00";
  } else {
    //calculations for days, hours, minutes and seconds
    var days = Math.floor(TimeDistance / (1000 * 60 * 60 * 24));
    var hours: any = Math.floor(
      (TimeDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes: any = Math.floor(
      (TimeDistance % (1000 * 60 * 60)) / (1000 * 60)
    );
    var seconds: any = Math.floor((TimeDistance % (1000 * 60)) / 1000);

    if (hours < 10) {
      hours = "0" + hours;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    DisplayTime = hours + ":" + minutes + ":" + seconds;
  }
  return DisplayTime;
}

export function GetSelectedIDsFromListWithComma(p_strID: any) {
  var l_intSelectedIDs = "";
  var l_strList = document.getElementsByName(p_strID) as any;

  for (var i = 0, length = l_strList.length; i < length; i++) {
    if (l_strList[i].checked) {
      if (l_intSelectedIDs == "") l_intSelectedIDs = l_strList[i].value;
      else l_intSelectedIDs = l_intSelectedIDs + "," + l_strList[i].value;
    }
  }
  return l_intSelectedIDs;
}
export const getTimeDifferenceHHMM = (
  startDate: Date,
  endDate: Date
): string => {
  const differenceInMinutes = Math.floor(
    (endDate.getTime() - startDate.getTime()) / (1000 * 60)
  );

  const hours = Math.floor(differenceInMinutes / 60)
    .toString()
    .padStart(2, "0");

  const minutes = (differenceInMinutes % 60).toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

//////////// AR: Checkboxes/RadioButtons functions starts /////////

export function GetSelectedIDsFromList(p_strID: any) {
  var l_intSelectedIDs = "-1";
  var l_strList = document.getElementsByName(p_strID) as any;

  for (var i = 0, length = l_strList.length; i < length; i++) {
    if (l_strList[i].checked) {
      l_intSelectedIDs = l_intSelectedIDs + "," + l_strList[i].value;
    }
  }
  l_intSelectedIDs = l_intSelectedIDs.replace("-1,", "");
  return l_intSelectedIDs;
}

export function SelectUnSelectSpecificElement(
  p_strID: any,
  p_intValue: any,
  p_boolSelect: any
) {
  var l_strList: any = document.getElementsByName(p_strID);

  for (var i = 0, length = l_strList.length; i < length; i++) {
    if (p_intValue == l_strList[i].value) {
      l_strList[i].checked = p_boolSelect;
    }
  }
}

export function SelectUnSelectAllElement(p_strID: any, p_boolChecked: any) {
  var l_strList: any = document.getElementsByName(p_strID);

  for (var i = 0, length = l_strList.length; i < length; i++) {
    l_strList[i].checked = p_boolChecked;
  }
}

export function SelectUnselectAllButOne(
  p_strID: any,
  p_intValue: any,
  p_boolSelect: any
) {
  var l_strList: any = document.getElementsByName(p_strID);

  for (var i = 0, length = l_strList.length; i < length; i++) {
    if (p_intValue == l_strList[i].value) {
      l_strList[i].checked = p_boolSelect;
    } else {
      l_strList[i].checked = !p_boolSelect;
    }
  }
}

export function CheckIfSelectedAnyElement(p_strID: any) {
  var l_boolIsSelectedAny = false;
  var l_strList: any = document.getElementsByName(p_strID);

  for (var i = 0, length = l_strList.length; i < length; i++) {
    if (l_strList[i].checked) {
      l_boolIsSelectedAny = true;
    }
  }
  return l_boolIsSelectedAny;
}

export function CheckIfSelectedAllElement(p_strID: any) {
  var l_boolIsSelectedAll = true;
  var l_strList: any = document.getElementsByName(p_strID);

  for (var i = 0, length = l_strList.length; i < length; i++) {
    if (l_strList[i].checked) {
    } else {
      l_boolIsSelectedAll = false;
    }
  }
  return l_boolIsSelectedAll;
}

//////////// AR: Checkboxes/RadioButtons functions ends /////////

//////////// AR: Select / unselect  starts /////////

export function SelectUnSelectPTItem(
  chkSelectUnSelect: any,
  chkSelectUnSelectAll: any
) {
  if (CheckIfSelectedAllElement(chkSelectUnSelect)) {
    let element: any = document.getElementById(chkSelectUnSelectAll);
    element.checked = true;
  } else {
    let element: any = document.getElementById(chkSelectUnSelectAll);
    element.checked = false;
  }
}

export function SelectUnSelectAll(
  chkSelectUnSelectAll: any,
  chkSelectUnSelect: any
) {
  let element: any = document.getElementById(chkSelectUnSelectAll);
  SelectUnSelectAllElement(chkSelectUnSelect, element.checked);
}

//////////// AR: Select / unselect  ends /////////

export function SortTheList(List: any, SortBy: any, Direction: any) {
  if (Direction == "A") return List.sort(GetSortOrderAsc(SortBy));
  else return List.sort(GetSortOrderDesc(SortBy));
}

function GetSortOrderAsc(prop: any) {
  return function (a: any, b: any) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

function GetSortOrderDesc(prop: any) {
  return function (a: any, b: any) {
    if (a[prop] < b[prop]) {
      return 1;
    } else if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}

export function getUniqueRecordsAgainstNthColumn(
  p_oGridData: any,
  p_strColumnName: any
) {
  var lookup = {} as any;
  var l_oArrayOfUniqueValues = [];
  for (var item, i = 0; (item = p_oGridData[i++]); ) {
    var name = item[p_strColumnName];
    if (name != "" || name == 0) {
      if (!(name in lookup)) {
        lookup[name] = 1;
        l_oArrayOfUniqueValues.push(name);
      }
    }
  }
  return l_oArrayOfUniqueValues.sort();
}

export function checkIfItemIsIntheList(p_strValue: string, l_oValueList: any) {
  let hasValueIntheList = false;
  if ($.inArray(p_strValue, l_oValueList) > -1) {
    hasValueIntheList = true;
  }
  return hasValueIntheList;
}
export function SanitizeHtml(html: any) {
  var tagOrComment =
    /<(?:!--[\s\S]*?--|script[\s\S]*?<\/script[^>]*>|style[\s\S]*?<\/style[^>]*|\/?[a-z][^>]*?)>/gi;
  return html.replace(tagOrComment, "").replace(/</g, "&lt;");
}
// Download in Excel function
export function DownloadClientsDataInExcel(id: any, name: any) {
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
    document.getElementById(id)
  );
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, name);

  XLSX.writeFile(wb, name + ".xlsx");
}

// Download in PDF function
export function exportPdf(tblid: any, fileName: any) {
  const doc = new jsPDF({ orientation: "landscape" });
  const table = document.getElementById(tblid);
  if (!table) {
    console.error("Table not found");
    return;
  }

  // Extract headers dynamically
  const headers: string[] = [];
  const headerCells = table.querySelectorAll("thead tr th");
  headerCells.forEach((header) => {
    headers.push(header.textContent?.trim() || "");
  });
  // Extract data dynamically including checkboxes and text input fields
  const data: string[][] = [];
  const rows = table.querySelectorAll("tbody tr");

  rows.forEach((row) => {
    const rowData: string[] = [];
    row.querySelectorAll("td").forEach((cell) => {
      // Handle checkboxes
      const checkbox = cell.querySelector(
        "input[type='checkbox']"
      ) as HTMLInputElement | null;
      if (checkbox) {
        rowData.push(checkbox.checked ? "on" : ""); // "On" if checked, blank if unchecked
      }
      // Handle text inputs
      else {
        const input = cell.querySelector(
          "input[type='text']"
        ) as HTMLInputElement | null;
        if (input) {
          rowData.push(input.value.trim()); // Use the input field value
        } else {
          rowData.push(cell.textContent?.trim() || ""); // Regular text content
        }
      }
    });
    data.push(rowData);
  });

  autoTable(doc, {
    head: [headers],
    body: data,
  });
  doc.save(fileName + ".pdf");
}

export function formateDate(p_date: any, withSeconds: boolean) {
  {
    let date;

    if (withSeconds == true) {
      date = moment(AppContext.ConvertDateTimeToLocal(p_date)).format(
        "DD/MM/YYYY HH:MM"
      );
    } else {
      date = moment(AppContext.ConvertDateTimeToLocal(p_date)).format(
        "DD/MM/YYYY"
      );
    }
    return date;
  }
}

export function formateDateAccordingToSelection(
  p_date: any,
  withSeconds: boolean,
  DateFormate: number
) {
  {
    let date;
    if (DateFormate == 1) {
      // UK
      if (withSeconds == true) {
        date = moment(AppContext.ConvertDateTimeToLocal(p_date)).format(
          "DD/MM/YYYY HH:MM"
        );
      } else {
        date = moment(AppContext.ConvertDateTimeToLocal(p_date)).format(
          "DD/MM/YYYY"
        );
      }
    }

    if (DateFormate == 2) {
      // US
      if (withSeconds == true) {
        date = moment(AppContext.ConvertDateTimeToLocal(p_date)).format(
          "MM/DD/YYYY HH:MM"
        );
      } else {
        date = moment(AppContext.ConvertDateTimeToLocal(p_date)).format(
          "MM/DD/YYYY"
        );
      }
    }

    return date;
  }
}

export function currentYearDisplay() {
  const currentYear = new Date().getFullYear();
  return currentYear;
}

export function addCommas(num: any) {
  if (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "";
  }
}

export function removeNonNumeric(num: any) {
  if (num) {
    return num.toString().replace(/[^0-9.]/g, "");
  } else {
    return "";
  }
}

export function removeNonNumericAndConvertIntoNumber(num: any) {
  let value = num.toString().replace(/[^0-9]/g, "");
  return parseInt(value);
}

export const getStartOfMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), 1).toLocaleDateString(
    "en-CA"
  );
};

export const getEndOfMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).toLocaleDateString(
    "en-CA"
  );
};

export const getCurrentMonthNumber = () => {
  const now = new Date();
  return now.getMonth() + 1; // getMonth() is zero-based, so we add 1
};
