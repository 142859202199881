import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import AppContext from "../Context/Context";
import { useGlobalState } from "../Context/GlobalStateContext";
import NavigateURL from "../Shared/NavigateURL";
export default function RequireAuth({ children }: any) {
  const location = useLocation();
  let completeURL = location.pathname + location.search;
  const [IsUserLoggedIn, setIsUserLoggedIn] = useState(true);
  const [OkToGo, setOkToGo] = useState(false);

  const globalState = useGlobalState();

  useEffect(() => {
    const CheckIsUserLoggedIn = async () => {
      // const Local_Token = process.env.REACT_APP_Local_Token;
      // if (
      //   Local_Token &&
      //   Local_Token !== "" &&
      //   !AppContext.IsJwtExpired(Local_Token)
      // ) {
      //   SetUserInfo(1, Local_Token);
      //   return true;
      // } else {
      //   let ReturnData = await AppContext.CheckLoginValidity();
      //   if (ReturnData.role === "") {
      //     return false;
      //   } else {
      //     SetUserInfo(2, ReturnData);
      //     return true;
      //   }
      // }

      let ReturnData = await AppContext.CheckLoginValidity(false);
      if (ReturnData !== "" && ReturnData.role === "") {
        return false;
      } else {
        SetUserInfo(1, ReturnData);
        return true;
      }
    };

    const SetUserInfo = (type: number, data: any) => {
      if (type === 1) {
        if (data && data !== "") var UserInfo: any = jwt_decode(data);
        if (UserInfo && UserInfo !== "") {
          globalState.setLogInUserName(UserInfo.username);
          globalState.setLogInUserImage(UserInfo.userimage);
          globalState.setLogInUserRole(UserInfo.role);
        }
      } else {
        globalState.setLogInUserName(data.username);
        globalState.setLogInUserImage(data.userimage);
        globalState.setLogInUserRole(data.role);
      }
    };

    (async () => {
      if ((await CheckIsUserLoggedIn()) === true) {
        // let havePermission = await AppContext.CheckAccessAndSettingsNDT(
        //   completeURL
        // );
        // if (havePermission) {

        //   let AccessData = await AppContext.GetUserApplicationAccessSettings();
        //   globalState.setAccessData(AccessData[0]);

        //   for (let index = 0; index < AppContext.AdminScreens.length; index++) {
        //     if(AppContext.AdminScreens[index] == completeURL){
        //       if(AppContext.ManageObj.JLRAdmin != 1){
        //         window.location = "/overview" as any;
        //       }
        //     }
        //   }

        //   setIsUserLoggedIn(true);
        // } else {
        //   setIsUserLoggedIn(false);
        // }

        // for (let index = 0; index < AppContext.AdminScreens.length; index++) {
        //   if (AppContext.AdminScreens[index] == completeURL) {
        //     if (AppContext.LogInUserRole != "Admin") {
        //       window.location = "/" as any;
        //     }
        //   }
        // }

        setIsUserLoggedIn(true);
      } else {
        setIsUserLoggedIn(false);
      }
      setOkToGo(true);
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  let ReturnData = "" as any;
  if (IsUserLoggedIn === true && OkToGo) {
    ReturnData = children;
  } else if (IsUserLoggedIn === false && OkToGo) {
    ReturnData = <NavigateURL />;
  }
  return ReturnData;
}
